import React from "react";
import { Page, ScreenProps, ScreenState } from "..";
import { Pages } from "../pages";
import { RandomBlob } from "../utils/RandomBlob";
import { Anchor, Div, H1, P, Section } from "../utils/tag.utils";
import { goTo } from "../utils/utils";
import { Service } from "./Services.const";

interface ServicesDetailProps extends ScreenState {
  service: Service;
}

export const ServicesDetailPage = ({
  service,
  ...props
}: ServicesDetailProps | any) => {
  const { width, height } = props;
  return (
    <Page {...props}>
      <Div ref={props.pageRef} className="bg-primary-1 text-black p-0">
        <Div style={{ maxWidth: "100%", maxHeight: "100%", width, height }}>
          <Div className="container o-hidden m-auto w-100">
            <Div className="mt-4">
              <Div className="row justify-content-center">
                <H1 className="col-12 col-md-8 bg-primary-opacity p-4 m-0">
                  {service.title}
                </H1>
              </Div>
              <Div className="row justify-content-center">
                <P className="col-12 col-md-8 bg-primary-opacity p-4">
                  {service.description}
                </P>
              </Div>
              {service.getDetails && (
                <Div className="row justify-content-center">
                  <Div className="col-12 col-md-8 bg-primary-opacity p-4">
                    {service.getDetails(props)}
                  </Div>
                </Div>
              )}
              <Div className="row justify-content-center p-4">
                <Anchor
                  textStyle={{ fontSize: "2.5rem" }}
                  className="col-12 col-sm-8 text-align-center"
                  onPress={() => goTo(Pages.Services, props)}
                >
                  <P className="bg-primary-opacity p-4">All Services</P>
                </Anchor>
              </Div>
            </Div>
          </Div>
        </Div>
        <Div
          className="blob blob-2 top left right bottom"
          style={{ position: "fixed"}}
        >
          {Array(parseInt(`${50 + Math.random() * 100}`))
            .fill(0)
            .map((_, i) => (
              <RandomBlob key={i} />
            ))}
        </Div>
      </Div>
    </Page>
  );
};
