import {
  Dispatch,
  forwardRef,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { ScreenState } from "..";
import { Div, Section, H3, P, Anchor, H4 } from "../utils/tag.utils";
import { ContentfulService, Service, Services } from "./Services.const";
import { formatUrl, goTo } from "../utils/utils";
import { Breakpoints } from "../consts/breakpoints.const";
import axios from "axios";

interface ServicesSectionProps extends ScreenState {
  maxEntries?: number;
}

export const ServicesSection = forwardRef(
  (
    { navigation, maxEntries, ...props }: ServicesSectionProps | any,
    ref: RefObject<typeof Section>
  ) => {
    const [services, setServices]: [
      ContentfulService[],
      Dispatch<SetStateAction<ContentfulService[]>>
    ] = useState<ContentfulService[]>([]);
    let little: boolean = false;
    useEffect(() => {
      try {
        if (props.breakpoints) {
          const { xxsmall, xsmall }: Partial<Breakpoints<boolean>> =
            props.breakpoints;
          little = xxsmall || xsmall;
        }
      } catch (e) {
        console.error(e);
      }
    }, [props.breakpoints]);
    useEffect(() => {
      async function fetchData() {
        console.log("fetching data");
        const response = await axios.get<ContentfulService[]>(
          "https://devcoup.com/api/contents/services"
        );
        let contentfulServices: ContentfulService[] = response.data;
        contentfulServices = contentfulServices.sort(() => Math.random() - 0.5);
        props.setLinking((oldLinking) => ({
          ...oldLinking,
          config: {
            ...oldLinking.config,
            screens: {
              ...oldLinking.config.screens,
              ...Object.fromEntries(
                contentfulServices
                  .map((item) => {
                    const link =
                      "/services/" +
                      item.title.toLowerCase().replace(/[^a-z]+/g, "-");
                    console.log(item.title, link);
                    return !link ? null : [link, formatUrl(link)];
                  })
                  .filter(Boolean)
              ),
            },
          },
        }));
        setServices(contentfulServices);
        console.log("fetching data", contentfulServices);
      }
      fetchData();
    }, [props.pageRef, props.linking]);
    useEffect(() => {
      console.log("withdeps", maxEntries, services);
      if (services.length > maxEntries) {
        let contentfulServices: ContentfulService[] = services;
        let start = Math.floor(
          (contentfulServices.length - maxEntries) * Math.random()
        );
        let end = start + maxEntries;
        console.log("asdf", start, contentfulServices);
        contentfulServices = contentfulServices.slice(start, end);
        console.log("asdf2", start, contentfulServices);
        // contentfulServices= contentfulServices.map((v,i) => i<maxEntries?v:null).filter(Boolean);
        setServices(contentfulServices);
      }
    }, [maxEntries, services]);
    const displayServices = services.map(
      (service: ContentfulService, i: number) => (
        <ServiceItem
          key={i}
          service={service}
          navigation={navigation}
          {...props}
        />
      )
    );
    return (
      <Section
        ref={ref}
        className="bg-primary-2 text-black"
        style={{
          marginTop: "-4rem",
          marginBottom: little ? "4rem" : 0,
          zIndex: 1,
          borderRadius: little ? 30 : "42% 32% 58% 62% / 33%",
          boxShadow: "white 0px -10px 0px 10px, white 0px 10px 0px 10px",
        }}
      >
        <Div className="container">
          <Div className="row Section-title justify-content-center text-center pb-4">
            <Div className="col-8 col-md-9 col-lg-8 col-xl-7">
              <H3 className="display-4 text-white">
                {maxEntries
                  ? Services.title
                  : Services.title.replace("Random ", "")}
              </H3>
              <Div className="lead text-white">
                {Services.description.replace(
                  maxEntries ? /all/ : "asdf",
                  "some of"
                )}
              </Div>
            </Div>
          </Div>
          <Div className="row justify-content-center">{displayServices}</Div>
        </Div>
      </Section>
    );
  }
);

interface ServicesItemProps extends ScreenState {
  service: ContentfulService;
}

const ServiceItem = ({ service, ...props }: ServicesItemProps) => {
  const { title, content: description } = service;
  const link = "/services/" + title.toLowerCase().replace(/[^a-z]+/g, "-");
  const [refs, setRefs] = useState<RefObject<HTMLElement>[]>([]);
  const [height, setHeight] = useState(0);
  useEffect(() => {
    if (refs.length !== 0) {
      setRefs([...refs, useRef(null)]);
    }
  }, [refs[refs.length - 1]]);
  refs.forEach(
    (ref, i) => {
      useEffect(() => {
        if (ref.current?.offsetHeight > height) {
          setHeight(ref.current?.offsetHeight);
        }
      }),
        [ref.current?.offsetHeight];
    },
    [refs]
  );
  useEffect(() => {
    if (
      refs.length !== 0 &&
      refs[refs.length - 1].current?.offsetHeight > height
    ) {
      refs[refs.length - 1].current.style.height = "0px";
      setTimeout(() => {
        if (refs[refs.length - 1].current) {
          refs[refs.length - 1].current.style.height = height + "px";
        }
      }, 100);
    }
  }, [height]);
  return (
    <Div
      key={title}
      style={{ minHeight: "100px" }}
      className="col-md-6 mb-3 mb-md-4 aos-init aos-animate"
      data-aos="fade-up"
      data-aos-delay="20"
    >
      <Div
        ref={refs[refs.length - 1]}
        className="card card-body bg-primary-1 min-vh-md-30 hover-box-shadow"
      >
        <Div className="flex-fill">
          <H4 style={{ color: "var(--primary-3)" }}>{title}</H4>
          <P>{description}</P>
          <P>&nbsp;</P>
        </Div>
        <Anchor onPress={() => goTo(link, props)} className="stretched-link">
          Learn More
        </Anchor>
      </Div>
    </Div>
  );
};
