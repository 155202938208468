import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Image } from "react-native";
import { ScreenState } from "..";
import breakpointsConst from "../consts/breakpoints.const";
import { Anchor, Div, H1, H6, P, Section } from "../utils/tag.utils";

import { CaseStudy } from "./CaseStudies.const";

export interface Props extends Partial<ScreenState> {
  caseStudy: CaseStudy;
}

export const A_404 = () => (
  <Div>
    <H1>404</H1>
    <H1>Page not found</H1>
    <Anchor href="/" className="btn btn-primary">
      Go back to home
    </Anchor>
  </Div>
);

export const CaseStudiesDetail = ({ caseStudy, ...props }) => {
  const { pageRef, headerRef } = props;

  const { xxsmall, xsmall, small } = breakpointsConst.getBreakpoints(
    props.width
  );

  const [little, setLittle]: [boolean, Dispatch<SetStateAction<boolean>>] =
    useState(xxsmall || xsmall || small);

  if (!caseStudy) {
    return <A_404 />;
  }
  const { description, image, banner, details } = caseStudy;
  const { menu, title, quote, article } = details;

  const heroRef = useRef(null);
  const heroImgRef = useRef(null);
  const stickyRef = useRef(null);
  const sectionRef = useRef(null);
  const secondSectionRef = useRef(null);

  useEffect(() => {
    const onScroll = () => {
      const { y } = pageRef.current.getContentOffset();
      const { height } = headerRef.current.getBoundingClientRect();
      const { height: heroHeight } = heroRef.current.getBoundingClientRect();
      const { height: heroImgHeight } =
        heroImgRef.current.getBoundingClientRect();
      const { height: stickyHeight } =
        stickyRef.current.getBoundingClientRect();
      const { height: sectionHeight } =
        sectionRef.current.getBoundingClientRect();
      const scrollTop = y + heroHeight;
      const scrollBottom = y + heroHeight + heroImgHeight;
      const scrollMiddle = y + heroHeight + heroImgHeight / 2;
      const scrollBottomSticky = y + heroHeight + heroImgHeight + stickyHeight;
      const scrollBottomSection =
        y + heroHeight + heroImgHeight + stickyHeight + sectionHeight;
      if (scrollTop < scrollMiddle) {
        setLittle(true);
      } else if (scrollTop > scrollMiddle) {
        setLittle(false);
      }
      if (scrollTop > scrollBottom) {
        setLittle(false);
      }
      if (scrollTop > scrollBottomSticky) {
        setLittle(false);
      }
      if (scrollTop > scrollBottomSection) {
        setLittle(false);
      }
    };
    pageRef.current.addEventListener("scroll", onScroll);
    return () => pageRef.current.removeEventListener("scroll", onScroll);
  }, [pageRef, headerRef, heroRef, heroImgRef, stickyRef, sectionRef]);

  useEffect(() => {
    if (heroRef.current && heroImgRef.current) {
      heroImgRef.current.style.height = heroRef.current.offsetHeight + "px";
    }
  }),
    [heroRef, heroImgRef];

  useEffect(() => {
    if (!little && stickyRef.current && pageRef.current && headerRef.current) {
      if (pageRef.current.offsetTop > stickyRef.current.offsetTop) {
        stickyRef.current.style.top =
          headerRef.current.offsetHeight + 10 + "px";
      }
    }
  }, [pageRef.current, headerRef.current, stickyRef.current, little]);

  const [minHeight, setMinHeight] = useState(heroRef.current?.offsetHeight);
  useEffect(() => {
    setMinHeight(heroRef.current?.offsetHeight || props.height / 2);
  }, [heroRef.current?.offsetHeight, little, props.height]);

  useEffect(() => {
    if (small && heroImgRef.current && heroRef.current) {
      let offset =
        heroImgRef.current.offsetHeight - heroRef.current.offsetHeight;
      heroRef.current.style.top =
        -(offset + heroRef.current.offsetHeight) + "px";
      secondSectionRef.current.style.top = -heroRef.current.offsetHeight + "px";
      secondSectionRef.current.style.paddingTop = "3.5rem";
    } else {
      heroImgRef.current.style.top = "0px";
      secondSectionRef.current.style.top = "0px";
      secondSectionRef.current.style.paddingTop = "6rem";
    }
  }, [
    small,
    props.width,
    props.height,
    heroRef.current,
    heroImgRef.current,
    minHeight,
    secondSectionRef.current,
  ]);

  return (
    <>
      <Section
        ref={sectionRef}
        className="p-0 border-top border-bottom bg-light row no-gutters"
      >
        <Div
          className="col-lg-5 col-xl-6 order-lg-2"
          style={{ width: "100%", minHeight }}
        >
          <Div className="divider divider-side transform-flip-y bg-light d-none d-lg-block"></Div>
          <Image
            ref={heroImgRef}
            style={{}}
            {...{ className: "flex-fill", alt: title }}
            source={banner}
          />
        </Div>
        <Div ref={heroRef} className="col-lg-7 col-xl-6">
          <Div className="container mb-5 min-vh-lg-70 min-vh-xl-80 d-flex align-items-center">
            <Div className="row justify-content-center">
              <Div
                className="col col-md-10 col-xl-9 py-4 py-sm-5"
                style={{ backgroundColor: small ? "#00000088" : "transparent" }}
              >
                <Div className="my-4 my-md-5 my-lg-0 my-xl-5">
                  <Image source={image} {...{ alt: title }} />
                  <H1
                    className="display-4 mt-4 mt-lg-5"
                    style={{ color: small ? "white" : "black" }}
                  >
                    &ldquo;{quote}&rdquo;
                  </H1>
                  <P
                    style={{ color: small ? "white" : "black" }}
                    className="lead"
                  >
                    {description}
                  </P>
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Section>
      <Section ref={secondSectionRef}>
        <Div className="container mt-0">
          <Div className="row align-items-start justify-content-around">
            <Div
              ref={stickyRef}
              className="col-10 col-md-9 col-lg-4 col-xl-4 sticky-lg-top mb-5 mb-lg-0"
            >
              <ul className="list-group pr-xl-4">
                {Object.entries(menu).map(
                  (
                    [key, value]: [
                      string,
                      string | { url: string; text: string }
                    ],
                    index: number
                  ) => {
                    return (
                      <li
                        className="list-group-item px-4 py-3 d-flex justify-content-between"
                        key={index}
                      >
                        <H6 className="mb-0">{key}</H6>
                        <Div className="text-right">
                          {typeof value !== "object" ? (
                            value
                          ) : (
                            <Anchor href={value.url}>{value.text}</Anchor>
                          )}
                        </Div>
                      </li>
                    );
                  }
                )}
              </ul>
            </Div>
            <Div className="col-xl-7 col-lg-8 col-md-9">{article}</Div>
          </Div>
        </Div>
      </Section>
    </>
  );
};
