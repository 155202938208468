import { Div } from "./tag.utils";

export const RandomBlob = () => {
  return (
    <Div
      style={{
        width: "" + (Math.random() > .5 ? -1 : 1) * Math.random() * 100 + "%",
        height: "" + ((Math.random() > .5 ? -1 : 1) * Math.random()) * 100 + "%",
        backgroundColor:
          "#" +
          Math.floor(((Math.random()*16777215/2)+(Math.random() * 16777215/2))).toString(16),
        position: "absolute",
        top: "" + (Math.random() > .5 ? -1 : 1) * Math.random() * 100 + "%",
        left: "" + (Math.random() > .5 ? -1 : 1) * Math.random() * 100 + "%",
        transform: "rotate(" + Math.random() * 360 + "deg)",
        borderRadius:
          Math.random() * 100 +
          "% " +
          Math.random() * 100 +
          "% " +
          Math.random() * 100 +
          "% " +
          Math.random() * 100 +
          "%",
        zIndex: -1,
      }}
    ></Div>
  );
};
