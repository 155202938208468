import axios from "axios";
import React, { forwardRef, useRef, useState } from "react";
import { useWindowDimensions } from "react-native";
import { Platform, TextInput } from "react-native";
import { Pages, usePages } from "../pages";
import { Div, Form, Span, Section, H1, Anchor } from "../utils/tag.utils";
import BREAKPOINTS from "../consts/breakpoints.const";
import { PageState, ScreenState } from "..";

const isWeb = Platform.OS === "web";

const postUrl = location.host.startsWith("localhost")
  ? "/api/leads"
  : "/api/leads";

export const ContactForm = ((props: ScreenState) => {
  const { width } = useWindowDimensions();
  const small = width < BREAKPOINTS.xsmall;
  const sectionRef = useRef(null);
  const submitRef = useRef(null);
  const onSubmit = async ($event) => {
    $event.preventDefault();
    if (sectionRef.current && submitRef.current) {
      sectionRef.current.style.opacity = 0.1;
      submitRef.current.disabled = true;
    }
    try {
      const response = await axios.post(postUrl, {
        lead_name: nameRef.current?.value,
        lead_email: emailRef.current?.value,
        company_name: companyRef.current?.value,
        company_id: 0,
        company_phone_number: phoneRef.current?.value,
        project_id: 0,
        project_name: projectRef.current?.value,
        project_budjet: budgetRef.current?.value,
        lead_message: messageRef.current?.value,
      });
      if (response) {
        await props.navigation.navigate(Pages.HomePage);
        alert("Thank you for contacting us!");
      }
    } catch (e) {
      alert(`Error: ${e.message}`);
    }
    if (sectionRef.current && submitRef.current) {
      sectionRef.current.style.opacity = 1;
      submitRef.current.disabled = false;
    }
    return false;
  };
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const companyRef = useRef(null);
  const phoneRef = useRef(null);
  const projectRef = useRef(null);
  const budgetRef = useRef(null);
  const messageRef = useRef(null);
  const FlexDiv = (_props) => [
    <Span
      key="label"
      className="text-right flex-sm-row col-4 mb-0"
      style={{ color: "black" }}
    >
      {_props.label}
    </Span>,
    _props.children,
  ];
  const FormInput = forwardRef((_props, _ref) => (
    <Div className="d-flex flex-column col-12 collapse pt-2 pb-2">
      <TextInput
        ref={_ref}
        {..._props}
        style={{
          ...props.style,
          width: "100%",
          borderBottomColor: "black",
          borderBottomWidth: 1,
          outline: "none",
          color: "black",
          paddingVertical: 5,
          paddingHorizontal: 10,
          marginBottom: 10,
        }}
      ></TextInput>
    </Div>
  ));
  const Button = forwardRef((props, _ref) => (
    <Anchor
      ref={_ref}
      onPress={props.onPress}
      className="btn btn-primary mt-2 mb-2 btn-lg mx-sm-2 mx-lg-0 mr-lg-2 my-1 my-sm-0"
      style={{
        alignSelf: "center",
        marginHorizontal: 6,
        width: "90%",
        textAlign: "center",
        // borderRadius: isWeb ? "20%/100%" : 30,
        padding: 20,
        textColor: "#FFFFFF",
        marginBottom: 20,
        backgroundColor: "white",
        border: "10px double white",
        boxShadow: "inset 2px 2px 0px 2px black, inset -2px -2px 0px 2px red",
        borderRadius: isWeb ? "12% 18% 20% 32% / 53%" : 30,
        transform: props.opposite ? "skew(-2deg, 2deg)" : "skew(2deg, -2deg)",
      }}
      textStyle={{
        textAlign: "center",
        alignSelf: "center",
        color: "#FF0000",
        fontWeight: "bold",
        fontSize: 20,
        transform: props.opposite ? "skew(3deg, -3deg)" : "skew(-3deg, 3deg)",
      }}
    >
      {props.children}
    </Anchor>
  ));
  return (
    <Section
      ref={sectionRef}
      style={{
        borderRadius: "12% 22% 18% 32% / 33%",
        border: "10px solid white",
        boxShadow: "red -10px -10px, inset 0 0 0 20px black",
        margin: isWeb ? (small ? "5% 5% 10%" : "") : 30,
        marginTop: "5%",
        marginBottom: "10%",
        background: "white",
        padding: 40,
        transform: "skew(-3deg, 5deg)",
      }}
    >
      <Div className="d-flex flex-column flex-sm-row mt-4 mt-md-5 justify-content-center aos-init aos-animate">
        <Form
          className="flex-column col-12"
          ajax={{
            method: "POST",
            action: "http://localhost:3000/leads",
          }}
          onSubmit={onSubmit}
          style={{
            maxHeight: "100%",
            transform: "skew(3deg, -5deg)",
            // borderRadius: 30,
            // border: "10px solid white",
            // backgroundColor: "white",
          }}
        >
          <FlexDiv label="Name:">
            <FormInput ref={nameRef}></FormInput>
          </FlexDiv>
          <FlexDiv label="Email:">
            <FormInput ref={emailRef}></FormInput>
          </FlexDiv>
          <FlexDiv label="Company:">
            <FormInput ref={companyRef}></FormInput>
          </FlexDiv>
          <FlexDiv label="Phone:">
            <FormInput ref={phoneRef}></FormInput>
          </FlexDiv>
          <FlexDiv label="Project:">
            <FormInput ref={projectRef}></FormInput>
          </FlexDiv>
          <FlexDiv label="Budget:">
            <FormInput ref={budgetRef}></FormInput>
          </FlexDiv>
          <FlexDiv label="Message:">
            <FormInput ref={messageRef} numberOfLines={5}></FormInput>
          </FlexDiv>
        </Form>
      </Div>
      <Div
        className="d-flex flex-column mt-4 mt-md-5 justify-content-center aos-init aos-animate"
        data-aos="fade-right"
        data-aos-delay="300"
        style={{
          transform: "skew(3deg, -5deg)"
        }}
      >
        <Button ref={submitRef} onPress={onSubmit}>
          Send Message
        </Button>
        <Button onPress={() => props.navigation.navigate(Pages.HomePage)} opposite={true}>
          Go Back
        </Button>
        <Button onPress={() => props.navigation.navigate(Pages.AboutUs)}>
          About Enigma
        </Button>
      </Div>
    </Section>
  );
});
