import { forwardRef, RefObject } from "react";
import { Image, Platform, useWindowDimensions } from "react-native";
import { Pages } from "../pages";
import {
  Section,
  Div,
  H1,
  Span,
  P,
  Anchor,
} from "../utils/tag.utils";
import { ScreenState } from "..";

const isWeb = Platform.OS === "web";

export const Hero = forwardRef((state: ScreenState | any, ref: RefObject<typeof Section>) => {
  const { width } = useWindowDimensions();
  const { navigation } = state;
  return (
    <Section ref={ref} className="p-0 row no-gutters hero-section mb-2">
      <Div className="col-lg-7 col-xl-6">
        <Div
          className="container min-vh-lg-80 d-flex align-items-center"
          style={{ zIndex: 999 }}
        >
          <Div className="row justify-content-center">
            <Div className="col col-md-10 col-xl-9 text-center text-lg-left">
              <Section>
                <Div data-aos="fade-right" className="aos-init aos-animate">
                  <H1
                    className="home-hero-text display-3"
                    style={{ width: "100%" }}
                    nativeStyle={{
                      textAlign: "center",
                      fontSize: 24,
                      marginVertical: 24,
                    }}
                  >
                    <Span
                      data-aos="highlight-text"
                      data-aos-delay="200"
                      className="aos-init aos-animate"
                      nativeStyle={{
                        flex: 1,
                        color: "#FF0000",
                        fontWeight: "bold",
                        fontSize: 44,
                        marginBottom: -24,
                        marginTop: 24,
                      }}
                    >
                      Creators of Digital Things
                    </Span>
                  </H1>
                  <P
                    className="lead"
                    nativeStyle={{
                      flex: 1,
                      paddingHorizontal: 24,
                      textAlign: "center",
                      marginBottom: 24,
                      fontSize: 18,
                    }}
                  >
                    We Create Professional Web &amp; Mobile Based Products for
                    Startups &amp; Enterprise Companies
                  </P>
                </Div>
                <Div
                  className="d-flex flex-column flex-lg-row mt-4 mt-md-5 justify-content-center justify-content-lg-start aos-init aos-animate"
                  data-aos="fade-right"
                  data-aos-delay="300"
                >
                  <Anchor
                    onPress={() => navigation.navigate(Pages.Contact)}
                    className="btn btn-primary btn-lg mx-sm-2 mx-lg-0 mr-lg-2 my-1 my-sm-0"
                    style={{ zIndex: 999, boxShadow: "0 0 0px 2px #FFFFFF44" }}
                    nativeStyle={{
                      alignSelf: "center",
                      marginHorizontal: 6,
                      width: "90%",
                      textAlign: "center",
                      borderRadius: isWeb ? "20%/100%" : 30,
                      backgroundColor: "#FF0000",
                      padding: 20,
                      textColor: "#FFFFFF",
                      marginBottom: -20,
                    }}
                    textStyle={{
                      textAlign: "center",
                      alignSelf: "center",
                      color: "#FFFFFF",
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                  >
                    Send Us a Message
                  </Anchor>
                  <P></P>
                  <Anchor
                    // onPress={() => props.setPage(Pages.Contact)}
                    href="https://www.picktime.com/devcoup"
                    className="btn btn-primary btn-lg mx-sm-2 mx-lg-0 mr-lg-2 my-1 my-sm-0"
                    style={{ zIndex: 999, boxShadow: "0 0 0px 2px #FFFFFF44" }}
                    nativeStyle={{
                      alignSelf: "center",
                      marginHorizontal: 6,
                      width: "90%",
                      textAlign: "center",
                      borderRadius: isWeb ? "20%/100%" : 30,
                      backgroundColor: "#FF0000",
                      padding: 20,
                      textColor: "#FFFFFF",
                      marginBottom: -20,
                    }}
                    textStyle={{
                      textAlign: "center",
                      alignSelf: "center",
                      color: "#FFFFFF",
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                  >
                    Book Now
                  </Anchor>
                </Div>
              </Section>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div className="col-lg-5 col-xl-6 d-lg-flex flex-lg-column">
        <Div className="Divider Divider-side transform-flip-y bg-white d-none d-lg-block"></Div>
        <Div
          className="d-lg-flex flex-column flex-fill controls-hover flickity-enabled is-draggable"
          data-flickity='{ "imagesLoaded": true, "wrapAround":true, "pageDots":false, "autoPlay":true }'
          tabIndex="0"
        >
          <Div className="flickity--viewport" style={{ height: 588 }}>
            <Div className="flickity-slider">
              <Div
                className="carousel-cell text-center is-selected"
                style={{
                  overflow: "hidden",
                  borderRadius: "120px 200px 110px",
                  height: "96%",
                  marginBottom: "2%",
                  marginLeft: "2%",
                  marginTop: "2%",
                  maxWidth: "96%",
                  width: "96%",
                  boxShadow: "-10px -10px #FF0000",
                  zIndex: 3,
                  ...(width > 991
                    ? {
                        position: "absolute",
                        transform:
                          "skew(-2deg, 10deg) scale(0.9) translateY(10px)",
                      }
                    : {
                        transform:
                          "skew(-3deg, 7deg) scale(0.9) translateY(10px)",
                      }),
                }}
              >
                <Image
                  style={{
                    maxWidth: "96%",
                    height: "96%",
                    borderBottomLeftRadius: 200,
                    borderBottomRightRadius: 110,
                    borderTopLeftRadius: 120,
                    borderTopRightRadius: 200,
                    marginStart: "2%",
                    marginTop: "2%",
                    marginBottom: "2%",
                    width: "96%",
                    ...{boxShadow: "10px 10px white",}
                  }}
                  source={require("../../assets/chicago_web_firm_location-1.png")}
                  {...{alt:"Chicago Web Firm Location"}}
                ></Image>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
    </Section>
  );
});
