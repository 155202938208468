import { forwardRef } from "react";
import { Pages } from "../pages";
import { Div, H3, P, Section, Anchor } from "../utils/tag.utils";
import { goTo } from "../utils/utils";

export const CTA = forwardRef((props, ref) => {
  return (
    <Section
      ref={ref}
      style={{
        margin: "-6rem 2rem 2rem",
        backgroundColor: "#000",
        borderRadius: "20% 25% 30% 25% / 40%",
        paddingLeft: "4rem",
        paddingRight: "4rem",
        color: "#FFFFFF",
        boxShadow: "-10px 0 0 20px white",
        zIndex: 3,
        transform: "skew(-5deg, -3deg)",
      }}
    >
      <Div className="container" style={{ transform: "skew(5deg, 3deg)" }}>
        <Div className="row Section-title justify-content-center text-center">
          <Div className="col-md-9 col-lg-8 col-xl-7">
            <H3
              className="display-4 jumpstart-your-launch-pitch"
              style={{ color: "#FFFFFF" }}
            >
              Ready to Build an Astonishing Product?
            </H3>
            <Div className="lead">
              Save hours and dollars with our design and development process.
              <P>
                Also did we mention... launch your product sooner and with
                better results.
              </P>
            </Div>
          </Div>
        </Div>
        <Div className="row justify-content-center text-center mt-md-n4">
          <Div className="col-auto">
            <Anchor
              onPress={() => goTo(Pages.Contact, props)}
              className="btn btn-primary btn-lg"
              style={{
                borderRadius: 30,
                boxShadow: "0 0 0 10px white, 0 0 2px 10px black",
                backgroundColor: "white",
                marginTop: "3rem",
                textAlign: "center",
                padding: "1rem",
              }}
              textStyle={{
                fontSize: "1.4rem",
                color: "#000000",
                fontWeight: 800,
                textShadow: "0 0 1px black",
              }}
            >
              Talk About Your Project
            </Anchor>
          </Div>
        </Div>
      </Div>
    </Section>
  );
});
