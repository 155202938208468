import { ScreenState } from "..";
import { Anchor, H2, H3, H4, H5, H6, P } from "../utils/tag.utils";
import { goTo } from "../utils/utils";

export interface SERVICES {
  title: string;
  description: string;
  services: Service[];
}

export interface Service {
  title: string;
  description: string;
  getDetails?: (
    props: ScreenState
  ) => string | React.ReactNode | ((props: ScreenState) => React.ReactNode);
  link: string;
}

export interface ContentfulService {
  id: string;
  title: string;
  content: string;
  assetUrl: string;
}



export const Services: SERVICES = {
  title: "Random Things We Do",
  description:
    "We also specialize in an array of niche services. Checkout all our premium services below!",
  services: [
    {
      title: "Web Development",
      description:
        "We create and develop secure, scalable, responsive, and well-designed web applications. Small business page, social network, eCommerce site, or a full-blown ERP system we have you covered.",
      getDetails: () =>
        "Using modern technologies like React, Redux, and Node.js we create and develop secure, scalable, responsive, and well-designed web applications. Small business page, social network, eCommerce site, or a full-blown ERP system we have you covered.",
      link: "/services/web-development",
    },
    {
      title: "Mobile Development",
      description:
        "We specialize in building stunning iOS, Android, and hybrid mobile applications using technologies such as Java, Flutter, Swift, and React Native.",
      link: "/services/mobile",
      getDetails: (props: ScreenState) => (
        <>
          <H2>Mobile Development is the Future</H2>
          <P>
            At Enigma Key Industries we believe that Mobile Development is the
            future of web development and app development alike. Many of us
            already use our phone as our main computing device.
          </P>
          <P>
            We are a mobile development company that specializes in building
            stunning iOS, Android, and hybrid mobile applications using
            technologies such as Java, Flutter, Swift, and React Native.
          </P>
          <P>
            Using these technologies, we harness the power of mobile devices to
            optimize your customers' needs and desires at a the touch of a
            button, a finger's reach away.
          </P>
          <P>
            Soon you'll be able to do everything you ever dreamed of with your
            phone!
          </P>
          <P>
            Phones are becomeing more and more important in the modern world.
            With stronger and more powerful devices and batteries, mobile
            computing is inarguably the way of the future!
          </P>
          <H3>Apps for Everything!!</H3>
          <P>
            We build apps for all your needs. Whether you need a simple web app,
            a full-blown mobile app, or a hybrid app, we have you covered.
          </P>
          <P>
            Even high performance apps are built with the latest technologies
            and technologies that are in the cutting edge of the industry. We
            use the latest technologies to build the best apps for you.
          </P>
          <P>
            Native apps for performance and speed or a clean consistant user
            experince are built with Kotlin and Swift.
          </P>
          <P>
            Or highly available apps, which can be distributed to multiple
            different target systems can be built with speed and reliability
            with excelent design through hybrid approaches like
            {/* <Anchor onPress={() => goTo('/services/react-native', props)}>React Native</Anchor> */}
          </P>
        </>
      ),
    },
    {
      title: "Cloud Development",
      description:
        "We specialize in building and deploying cloud-based applications using technologies such as AWS, Azure, and Google Cloud Platform.",
      link: "/services/cloud",
    },
    {
      title: "Software Development",
      description:
        "We specialize in building and deploying software applications using technologies such as Java, .NET, and Python.",
      link: "/services/software",
      getDetails: (props: ScreenState) => (
        <>
          <H2>What Is Development</H2>
          <P>Development is the process of creating and modifying software.</P>
          <H3>Development Language</H3>
          <P>‘Development Language’ is the programming language that you use to write code.</P>
          <P>A programming language is a set of rules for writing programs.</P>
          <P>Languages take many forms, but the most common form is a programming language.</P>
          <H4>Application Development</H4>
          <P>Application development is the process of creating and modifying software.</P>
          <H5>System Developers</H5>
          <P>System developers are the people who develop the software that runs on a computer.</P>
          <H6>Game Developers</H6>
          <P>Game developers are the people who develop the software which powers gaming systems.</P>
          <H6>Development Languages</H6>
          <P>Development languages are the programming languages that you use to write code.</P>
          <H6>Qualities of a Great Software Developer</H6>
          <P>Qualities of a great software developer are:</P>
          <P> - Ability to understand the problem</P>
          <P> - Ability to think creatively</P>
          <P> - Ability to think analytically</P>
          <P> - Ability to think strategically</P>
          <P> - Ability to think independently</P>
          <P> - Ability to think like a team</P>
          <P> - Ability to work well under pressure</P>
          <H6>Software Development Process</H6>
          <P>Software development process is the process of creating and modifying software.</P>
          <H6>Software Development Life Cycle</H6>
          <P>Software development life cycle is the lifecyle of software project throughout development.</P>
        </>
      ),
    },
    {
      title: "Data Science",
      description:
        "We specialize in building and deploying data science applications using technologies such as R, Python, and Spark.",
      link: "/services/data-science",
    },
    {
      title: "DevOps",
      description:
        "We specialize in building and deploying DevOps applications using technologies such as Docker, Kubernetes, and Terraform.",
      link: "/services/devops",
      getDetails: (props: ScreenState) => (
        <>
          <H2>What is DevOps?</H2>
          <P>
            DevOps is a term used to describe the process of managing the
            development and deployment of software.
          </P>
          <H3>Use a Source Control System</H3>
          <P> Source control is the process of tracking changes to software.</P>
          <H4>What benefits does DevOps give to a company?</H4>
          <P> • It helps to keep software projects on track and on schedule.</P>
          <P>
            {" "}
            • It helps to ensure that software projects are safe and secure.
          </P>
          <P> • It helps to ensure that software projects are easy to use.</P>
          <P>
            {" "}
            • It helps to ensure that software projects are easy to maintain.
          </P>
          <P>
            {" "}
            • It helps to ensure that software projects are easy to deploy.
          </P>
          <P> • It helps to ensure that software projects are easy to test.</P>
          <H5>How to become a DevOps oriented team</H5>
          <P> • Identify the needs of your team.</P>
          <P> • Identify the needs of your project.</P>
          <P> • Identify the needs of your company.</P>
          <P> • Identify the needs of your organization.</P>
          <P> • Identify the needs of your industry.</P>
          <P> • Identify the needs of your customers.</P>
          <P> • Identify the needs of your suppliers.</P>
          <P> • Identify the needs of your partners.</P>
          <H6>Configuration management</H6>
          <P>
            Configuration management is the process of managing the
            configuration of software.
          </P>
        </>
      ),
    },
    {
      title: "Blockchain",
      description:
        "We specialize in building and deploying blockchain applications using technologies such as Ethereum, Hyperledger, and Solidity.",
      link: "/services/blockchain",
    },
    {
      title: "Artificial Intelligence",
      description:
        "We specialize in building and deploying artificial intelligence applications using technologies such as Tensorflow, Keras, and Pytorch.",
      link: "/services/ai",
    },
    {
      title: "ERP Systems",
      description:
        "We specialize in building and deploying ERP systems using technologies such as Oracle, SAP, and Microsoft.",
      link: "/service/erp",
    },
    {
      title: "Cloud Security",
      description:
        "We specialize in building and deploying cloud-based security applications using technologies such as AWS, Azure, and Google Cloud Platform.",
      link: "/services/cloud-security",
    },
    {
      title: "Blockchain Security",
      description:
        "We specialize in building and deploying blockchain security applications using technologies such as Ethereum, Hyperledger, and Solidity.",
      link: "/services/blockchain-security",
    },
    {
      title: "Artificial Intelligence Security",
      description:
        "We specialize in building and deploying artificial intelligence security applications using technologies such as Tensorflow, Keras, and Pytorch.",
      link: "/services/ai-security",
    },
    {
      title: "ERP Security",
      description:
        "We specialize in building and deploying ERP security applications using technologies such as Oracle, SAP, and Microsoft.",
      link: "/services/erp-security",
    },
    {
      title: "Unit Tests",
      description:
        "We specialize in building and deploying unit testing applications using technologies such as JUnit, Mockito, and TestNG.",
      link: "/services/unit-tests",
    },
    {
      title: "Integration Tests",
      description:
        "We specialize in building and deploying integration testing applications using technologies such as JUnit, Mockito, and TestNG.",
      link: "/services/integration-tests",
    },
    {
      title: "System Tests",
      description:
        "We specialize in building and deploying system testing applications using technologies such as JUnit, Mockito, and TestNG.",
      link: "/services/system-tests",
    },
    {
      title: "Performance Tests",
      description:
        "We specialize in building and deploying performance testing applications using technologies such as JUnit, Mockito, and TestNG.",
      link: "/services/performance-tests",
    },
    {
      title: "Load Tests",
      description:
        "We specialize in building and deploying load testing applications using technologies such as JUnit, Mockito, and TestNG.",
      link: "/services/load-tests",
    },
    {
      title: "Scalability",
      description:
        "We specialize in building and deploying scalability applications using technologies such as JUnit, Mockito, and TestNG.",
      link: "/services/scalability",
    },
    {
      title: "Security",
      description:
        "We specialize in building and deploying security applications using cutting edge technologies.",
      link: "/services/security",
      getDetails: (props: ScreenState) => (
        <>
          <H2>What is Security?</H2>
          <P>'Security' is the process of protecting systems from attacks.</P>
          <H3>Approach to Software Security</H3>
          <P>Security in sofware is important because it is the basis of the security of the system.</P>
          <P> • It is important to understand the security of the system.</P>
          <H4>Security Built-in SDLC</H4>
          <P>During the SDLC process, security is built-in via the following steps:</P>
          <P> • Identify the security needs of the system.</P>
          <P> • Anticipate potentail threats.</P>
          <P> • Proactively identify potential threats.</P>
          <P> • Implement security measures as developing the codebase.</P>
          <P> • Test the security of the system.</P>
          <P> • Review the security of the system.</P>
          <P> • Implement security measures as deploying the system.</P>
          <H5>External drivers and context</H5>
          <P> • External drivers are the bits of code to interface hardware and software, often called firmware.</P>
          <P> • External context is the environment in which the system is deployed.</P>
          <H6>Security Architecture</H6>
          <P> • Security architecture is the process of designing the security of the system.</P>
          <P> • It is important to understand the security architecture of the system.</P>
          <H6>Deficiencies of current solutions</H6>
          <P> • Deficiencies of current solutions are the weaknesses of the current solutions.</P>
          <P> • It is important to understand the weaknesses of the current solutions.</P>
          <H6>DARPA Cyber Grand Challenge</H6>
          <P> • The DARPA Cyber Grand Challenge is a competition to identify the security architecture of the system.</P>
        </>
      ),
    },
    {
      title: "Performance",
      description:
        "We specialize in building and deploying performance applications using technologies such as JUnit, Mockito, and TestNG.",
      link: "/services/performance",
      getDetails: (props: ScreenState) => (
        <>
          <H2>What is Performance?</H2>
          <P>'Performance' is the process of optimizing the performance of systems.</P>
          <H3>Approach to Performance</H3>
          <P>Performance in software is important because it is the basis of the performance of the system.</P>
          <H4>Performance Built-in SDLC</H4>
          <P>During the SDLC process, performance is built-in via the following steps:</P>
          <P> • Identify the performance needs of the system.</P>
          <P> • Anticipate potentail performance issues.</P>
          <P> • Proactively identify potential performance issues.</P>
          <P> • Implement performance measures as developing the codebase.</P>
          <P> • Test the performance of the system.</P>
          <P> • Review the performance of the system.</P>
          <P> • Implement performance measures as deploying the system.</P>
        </>
      ),
    },
    {
      title: "Android Development",
      description:
        "We specialize in building and developing Android applications using technologies such as Java, Flutter, and React Native.",
      link: "/services/android",
    },
    {
      title: "iOS Development",
      description:
        "We specialize in building and developing iOS applications using technologies such as Java, Flutter, and React Native.",
      link: "/services/ios",
    },
    {
      title: "Web Development",
      description:
        "We specialize in building and developing web applications using technologies such as Java, Flutter, and React Native.",
      link: "/services/web",
    },
    {
      title: "Agile Development",
      description:
        "We specialize in building and developing agile applications using technologies such as Java, Flutter, and React Native.",
      link: "/services/agile",
    },
    {
      title: "Java Development",
      description:
        "We specialize in building and developing Java applications using technologies such as Java, Flutter, and React Native.",
      link: "/services/java",
    },
    {
      title: "JavaScript Development",
      description:
        "We specialize in building and developing JavaScript applications using technologies such as Java, Flutter, and React Native.",
      link: "/services/javascript",
    },
    {
      title: "React Development",
      description:
        "We specialize in building and developing React applications using technologies such as Java, Flutter, and React Native.",
      link: "/services/react",
    },
    {
      title: "React Native Development",
      description:
        "We specialize in building and developing React Native applications using technologies such as Java, Flutter, and React Native.",
      link: "/services/react-native",
    },
    {
      title: "Flutter Development",
      description:
        "We specialize in building and developing Flutter applications using technologies such as Java, Flutter, and React Native.",
      link: "/services/flutter",
    },
    {
      title: "Angular Development",
      description:
        "We specialize in building and developing Angular applications using technologies such as Java, Flutter, and React Native.",
      link: "/services/angular",
    },
    {
      title: "AngularJS Development",
      description:
        "We specialize in building and developing AngularJS applications using technologies such as Java, Flutter, and React Native.",
      link: "/services/angularjs",
    },
    {
      title: "Node Development",
      description:
        "We specialize in building and developing Node applications using technologies such as Java, Flutter, and React Native.",
      link: "/services/node",
    },
    {
      title: "NodeJS Development",
      description:
        "We specialize in building and developing NodeJS applications using technologies such as Java, Flutter, and React Native.",
      link: "/services/nodejs",
    },
    {
      title: "Python Development",
      description:
        "We specialize in building and developing Python applications using technologies such as Java, Flutter, and React Native.",
      link: "/services/python",
    },
    {
      title: "PHP Development",
      description:
        "We specialize in building and developing PHP applications using technologies such as Java, Flutter, and React Native.",
      link: "/services/php",
    },
    {
      title: "C# Development",
      description:
        "We specialize in building and developing C# applications using technologies such as Java, Flutter, and React Native.",
      link: "/services/csharp",
    },
  ],
};
