import axios from "axios";
import React, { forwardRef } from "react";
import { Platform, useWindowDimensions } from "react-native";
import { Section, Div, H1, Span } from "../utils/tag.utils";
import { DiscordWidgetGroup } from "../widgets/discord.widget.group";
import { ContactForm } from "./ContactForm";
import { PageState } from "..";
import { Pages } from "../pages";

const isWeb = Platform.OS === "web";

export const Contact = (state: PageState | any) => {
  const { pageRef } = state;
  const { width } = useWindowDimensions();
  const small = width < 540;
  return (
    <Section className="p-0 row">
      <Div className="col mt-4 align-items-center">
        <Div className="row justify-content-center">
          <H1
            className={`contact-hero-text display-3${small ? ' mt-4' : ''}`}
            style={{ width: "100%", textAlign: "center" }}
          >
            <Span
              data-aos="highlight-text"
              data-aos-delay="200"
              className="aos-init aos-animate"
              nativeStyle={{
                flex: 1,
                color: "#FF0000",
                fontWeight: "bold",
                fontSize: 44,
              }}
            >
              Contact Us
            </Span>
          </H1>
        </Div>
      </Div>
      <Div className="col col-10 offset-1">
        <ContactForm {...state} setPage={(name: Pages) => state.navigation.navigate({ name } as any)}></ContactForm>
      </Div>
      <DiscordWidgetGroup {...{style:{marginTop: '4rem'}}} small={small} pageRef={pageRef} />
    </Section>
  );
};
