import React, { useEffect, useRef, useState } from "react";
import { Image, useWindowDimensions } from "react-native";
import { ScreenState } from "..";

import {
  Section,
  Div,
  H1,
  Span,
  Anchor,
  P,
} from "../utils/tag.utils";
import { goTo } from "../utils/utils";

import { CaseStudies } from "./CaseStudies.const";

export const CaseStudyIndex = (props: ScreenState) => {

  const { width } = useWindowDimensions();
  const { xxsmall, xsmall, small, medium, large, xlarge, xxlarge } = props.breakpoints;
  const colSizes = [xxsmall, xsmall, small, medium];
  let maxWidth = width;
  const [little, setLittle] = useState(xxsmall || xsmall || small);
  useEffect(() => {
    setLittle(colSizes.reduce(
      (acc, curr) => acc || curr,
      false
    ));
  } , [width]);

  return (
    <Div className="case-study-page">
      <Section className="bg-light py-5">
        <Div className="container">
          {CaseStudies.reverse().map((item, index) => {
            const imgRef = useRef(null);
            const rowRef = useRef(null);
            useEffect(() => {
              if (imgRef.current && rowRef.current) {
                imgRef.current.style.height =
                  rowRef.current.offsetHeight + "px";
                imgRef.current.style.width = little
                  ? rowRef.current.offsetWidth + "px"
                  : 300 + "px";
              }
            }, [imgRef.current, rowRef.current, little]);

            return (
              <Div
                className="row mt-4 mb-4 col-10 col-md-6 col-lg-4 col-xl-3"
                key={`case_study_${index}`}
              >
                <Anchor style={{display: 'felx'}} onPress={() => goTo(item.link, props)}>
                  <Div
                    ref={rowRef}
                    style={{ maxWidth }}
                    className="case-study-container d-flex flex-column flex-lg-row no-gutters border rounded bg-white o-hidden"
                  >
                    <Anchor
                      onPress={() => goTo(item.link, props)}
                      // href={item.link}
                      className="d-block position-relative bg-gradient col-xl-5"
                    >
                      <Image
                        ref={imgRef}
                        {...{
                          className: "flex-fill hover-fade-out",
                          alt: item.title,
                        }}
                        source={item.image}
                      />
                      <Div className="divider divider-side bg-white d-none d-lg-block"></Div>
                    </Anchor>
                    <Div className="p-4 p-md-5 d-flex align-items-center">
                      <Div className="p-lg-4 p-xl-5">
                        <Div className="text-right text-small text-muted case-study-highlight">
                          {item.tag}
                        </Div>
                        <Anchor
                          onPress={() => goTo(item.link, props)}
                        >
                          <H1>{item.title}</H1>
                        </Anchor>
                        <P className="lead">{item.description}</P>
                        <Anchor
                          onPress={() => goTo(item.link, props)}
                          className="lead"
                        >
                          Read Case Study
                        </Anchor>
                        <Div className="case-study-category-tags-container">
                          <Div className="d-flex justify-content-between align-items-center mb-3 mb-xl-4 case-study-category-tags">
                            {item.hashtags.map((hashtag) => (
                              <Span
                                key={`case_study_${index}_${hashtag}`}
                                style={{
                                  color: "white",
                                  backgroundColor: "black",
                                }}
                                className="badge badge-pill badge-danger"
                              >
                                {hashtag}
                              </Span>
                            ))}
                          </Div>
                        </Div>
                      </Div>
                    </Div>
                  </Div>
                </Anchor>
              </Div>
            );
          })}
        </Div>
      </Section>
    </Div>
  );
};
