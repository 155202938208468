import React, { forwardRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { CaseStudies } from "../CaseStudies/CaseStudies.const";
import { Div, H3, Section, P } from "../utils/tag.utils";
import { Testimonial } from "./testimonials/Testimonial";

export const Testimonials = forwardRef((props, ref) => {
  const [testimonials, setTestimonials] = React.useState([]);
  React.useEffect(() => {
    setTestimonials(
      CaseStudies.reverse().map((caseStudy) => (
        <Testimonial {...props} key={caseStudy.title} caseStudy={caseStudy} />
      ))
    );
  }, []);
  return (
    <Section
      ref={ref}
      style={{ zIndex: 1, marginTop: "-4.4rem", paddingTop: "5rem" }}
      className="home-testimonial-section text-white o-hidden pb-0"
    >
      <Div className="container mb-5">
        <Title {...props} />
        <TestimonialWrapper {...props}>{testimonials}</TestimonialWrapper>
      </Div>
    </Section>
  );
});

const Title = (props) => (
  <Div className="row section-title justify-content-center text-center">
    <Div className="col-md-9 col-lg-8 col-xl-7">
      <H3 className="display-4">{props.title || "Customers are lovin’ it"}</H3>
      <Div style={{ fontWeight: "bold" }} className="lead">
        {props.children ? (
          props.children || props.subtitle
        ) : (
          <P>
            We work with a wide range of companies from small business to
            enterprise. Every project is a new discovery that we truly enjoy
            exploring.
          </P>
        )}
      </Div>
    </Div>
  </Div>
);

const TestimonialWrapper = forwardRef((props, ref) => {
  const { breakpoints } = props;
  let little = false;
  if (breakpoints) {
    const { xxsmall, xsmall, small } = breakpoints;
    little = xxsmall || xsmall || small;
  }
  try {
    return (
      <Swiper
        ref={ref}
        modules={[Navigation, Pagination, A11y]}
        pagination={{ clickable: true }}
        navigation={!little}
        loop={true}
        resistanceRatio={0}
        spaceBetween="3%"
        slidesPerView={1}
        autoplay={{ delay: 0, disableOnInteraction: false }}
        style={{ minHeight: "25vh", paddingBottom: "5rem" }}
      >
        {React.Children.map(props.children, (child) => (
          <SwiperSlide key={child.key} >{child}</SwiperSlide>
        ))}
      </Swiper>
    );
  } catch (e) {
    console.error("TestimonialWrapper", e);
    return null;
  }
});
