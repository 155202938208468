import { forwardRef, useRef, useState } from "react";
import { Platform, TouchableOpacity } from "react-native";
import { Div, H2, P, Section, Span } from "../utils/tag.utils";
import { Breakpoints } from "../consts/breakpoints.const";

const isWeb = Platform.OS === "web";

export const Mission = forwardRef((props, ref) => {
  const redUStyle = {
    borderBottomWidth: 4,
    borderBottomColor: "#FF0000",
  };
  const pRef = useRef(null);
  const [sectionClassName, setSectionClassName] = useState(
    "mission-statement-section o-hidden collapsed"
  );
  const onPress = () => {
    if (sectionClassName.includes("collapsed")) {
      setSectionClassName(sectionClassName.split("collapsed").join("expanded"));
    } else {
      setSectionClassName(sectionClassName.split("expanded").join("collapsed"));
    }
  };
  const { breakpoints } = props as { breakpoints: Breakpoints<boolean> };
  const small =
    breakpoints?.xxsmall || breakpoints?.xsmall || breakpoints?.small;
  return (
    <Section
      ref={ref}
      onPress={onPress}
      className={sectionClassName}
      style={{
        borderRadius: isWeb ? "70% 40% 70% 60% / 33%" : 0,
        backgroundColor: "black",
        boxShadow: "0 10px #FFFFFF, 0 -10px #FFFFFF",
        margin: "-6rem 2% 20px",
        zIndex: 2,
        transofrm: "scale(0.9)",
      }}
      nativeStyle={{
        // marginTop: -180,
        marginBottom: 20,
        height: 333,
        borderTopLeftRadius: 120,
        borderTopRightRadius: 100,
        borderBottomRightRadius: 170,
        borderBottomLeftRadius: 116,
      }}
    >
      <Div className="container collapsed">
        <H2
          className="text-center"
          style={{ color: "white", fontSize: isWeb ? "50pt" : 50 }}
          nativeStyle={{ marginVertical: 20, alignSelf: "center" }}
        >
          Our Mission
        </H2>

        <P
          ref={pRef}
          className="mission-statement-text text-center"
          nativeStyle={{
            color: "#FFFFFF",
            padding: 20,
            textAlign: "center",
            fontSize: 22,
            fontFamily: "Helvetica Neue",
            lineHeight: 28,
            marginTop: -12,
          }}
        >
          <Span>
            is to inspire the foresighted recognition of the fact that:
          </Span>
          {(!small && (
            <>
              <br />
              <br />
            </>
          )) ||
            " "}
          <Span className="red-underline-text">
            The future will be run on AI and Blockchain
          </Span>
          {(!small && (
            <>
              <br />
              <br />
            </>
          )) ||
            " "}
          <Span>
            This simple truth, which resounded within us, inspired us to see
            what more we can learn and to spur an opportunity for ourselves, our
            dreams, and everyone.
          </Span>
          {(!small && (
            <>
              <br />
              <br />
            </>
          )) ||
            " "}
          <Span>
            This empowers and motivates our company with relentless
            determination to fulfill this mission!
          </Span>
          {(!small && (
            <>
              <br />
              <br />
            </>
          )) ||
            " "}
          <Span>
            Our Dream Future is when Enigma Key has released its flagship
            product, the{" "}
          </Span>
          <Span className="red-underline-text">“YachtOffice”</Span>
          <Span>
            {" "}
            across all major platforms, to help individuals across the world to
            find sustainable wealth.
          </Span>
          {(!small && (
            <>
              <br />
              <br />
            </>
          )) ||
            " "}
        </P>
      </Div>
    </Section>
  );
});
