export const BREAKPOINTS: Breakpoints = {
  xxsmall: 480,
  xsmall: 576,
  small: 768,
  medium: 992,
  large: 1024,
  xlarge: 1200,
  xxlarge: 1600,
};

export default {
  ...BREAKPOINTS,
  getBreakpoints: (width: number) =>
    ({
      xxsmall: width < BREAKPOINTS.xxsmall,
      xsmall: width < BREAKPOINTS.small && width >= BREAKPOINTS.xxsmall,
      small: width < BREAKPOINTS.medium && width >= BREAKPOINTS.small,
      medium: width < BREAKPOINTS.large && width >= BREAKPOINTS.medium,
      large: width < BREAKPOINTS.medium && width >= BREAKPOINTS.large,
      xlarge: width < BREAKPOINTS.large && width >= BREAKPOINTS.xlarge,
      xxlarge: width > BREAKPOINTS.xxlarge,
    }),
} as BREAKPOINTS;

export type Breakpoints<T = number> = {
    [key in BreakpointNames]: T;
};

export interface BREAKPOINTS extends Breakpoints<number> {
    getBreakpoints: (width: number) => Breakpoints<boolean>;
}
export type BreakpointNames =
  | "xxsmall"
  | "xsmall"
  | "small"
  | "medium"
  | "large"
  | "xlarge"
  | "xxlarge";

export interface BreakpointNumeric {
  xxsmall: number;
  xsmall: number;
  small: number;
  medium: number;
  large: number;
  xlarge: number;
  xxlarge: number;
}
export interface BreakpointBoolean {
  xxsmall: boolean;
  xsmall: boolean;
  small: boolean;
  medium: boolean;
  large: boolean;
  xlarge: boolean;
  xxlarge: boolean;
}
