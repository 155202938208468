import {
  Dispatch,
  forwardRef,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { ScreenState } from "../..";
import { CaseStudies, CaseStudy } from "../../CaseStudies/CaseStudies.const";
import { Div, Anchor, Image, H2 } from "../../utils/tag.utils";
import { goTo } from "../../utils/utils";

interface TestimonialBaseProps extends ScreenState {
  children?: React.ReactNode;
  caseStudy?: CaseStudy;
  title?: string;
  key?: string;
}

interface TestimonialPropsFromCaseStudy extends TestimonialBaseProps {
  caseStudy: CaseStudy;
}

interface TestimonialPropsFromTitle extends TestimonialBaseProps {
  title: string;
}

type TestimonialProps =
  | TestimonialPropsFromCaseStudy
  | TestimonialPropsFromTitle;

export const Testimonial = forwardRef(
  ({ caseStudy, title, ...props }: TestimonialProps, ref: RefObject<any>) => {
    const [dragging, setDragging] = useState(false);

    const [testimonial, setTestimonial]: [
      CaseStudy,
      Dispatch<SetStateAction<CaseStudy>>
    ] = useState(caseStudy);

    useEffect(() => {
      if (caseStudy) {
        setTestimonial(caseStudy);
      } else if (title) {
        setTestimonial(CaseStudies.find(({ title }) => title === title));
      }
    }, [caseStudy, title]);

    const goToCaseStudy = useCallback(() => {
      if (dragging) return;
      goTo(testimonial.link, props);
    }, [dragging, props, testimonial.link]);

    const onDragStart = useCallback(
      (e) => {
        console.log("onDragStart", e);
        setDragging(true);
      },
      [props, setDragging]
    );

    const onDragEnd = useCallback(
      (e) => {
        console.log("onDragEnd", e);
        setTimeout(() => {
          setDragging(false);
        }, 500);
      },
      [props, setDragging]
    );

    return (
      <Anchor
        key={props.key}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        ref={ref}
        onPress={goToCaseStudy}
      >
        <Div className="row align-items-center justify-content-start justify-content-sm-center mx-3 mx-xl-4">
          <Div className="col-sm-4 mb-4 mb-sm-0">
            <Image
              className="img-fluid bg-white m-0-25 avatar avatar-xl avatar-sm-big testimonial-avatar offset-1"
              source={testimonial.image}
              alt={testimonial.title}
            />
          </Div>
          <Div className="col">
            <H2 className="fa-4x">{testimonial.title}</H2>
            <Div className="text-white fa-2x">
              {(props.children && props.children) || testimonial.description}
            </Div>
          </Div>
        </Div>
      </Anchor>
    );
  }
);
