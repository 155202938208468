import { useRef } from "react";
import { Page, ScreenProps } from ".";
import { CTA } from "./sections/CTA";
import { Hero } from "./sections/Hero";
import { Mission } from "./sections/Mission";
import { Quote } from "./sections/Quote";
import { ServicesSection } from "./sections/Services";
import { Testimonials } from "./sections/Testimonials";

interface HomeProps extends ScreenProps {}

export const Home = (props: HomeProps | any) => {
  return (
    <Page {...props}>
      <Hero {...props} />
      <Mission />
      <Testimonials />
      <Quote by="@Enigma.CEO">
        | Dare to think outside the box. <br />
        | Dare to challenge the impossible. <br />
        | Accept that the future is now, and <br />
        | Remember, The YachtOffice is coming… <br />
      </Quote>
      <ServicesSection maxEntries={6} />
      <CTA />
    </Page>
  );
};
