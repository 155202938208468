import { ScreenState } from "..";

export const formatUrl = (url: string) => {
  if (url.includes('/')) {
    return url.split('/').slice(1).map(str => str.replace(/[^a-z0-9]/gi, "-")).join('/');
  } else {
    return url.replace(/[^a-z0-9]/gi, "-");
  }
};

export type NavString = String & {
    go: (params: ScreenState) => void;
    goWith: (params: ScreenState, extra?: any) => void;
}

export const NavString = (str: string) => {
    return {
        go: (params: ScreenState) => {
            navigateToUrl(str, params);
        },
        goWith: (params: ScreenState, extra: any) => {
            navigateToUrl(str, {...params, ...extra});
        }
    } as NavString;
}

export const goTo = (str: NavString | string, params: ScreenState, extra?: any) => {
    const navStr: NavString = str instanceof NavString 
        ? str as NavString 
        : NavString(str as string);
    navStr.goWith(params, extra);
};

export const navigateToUrl = (url: string, { navigation, ...props}: ScreenState, params: any = {}) => {
    if (navigation?.navigate) {
        let cleanUrl = formatUrl(url);
        // const unregistered = navigation.dangerouslyGetState().routes.find(route => route.name === cleanUrl);
        let cleanParams = {...params, ...(props.route?.params || {})};
        navigation.navigate(cleanUrl, cleanParams);
    } else {
        console.warn('navigateToUrl: params.navigation is undefined');
    }
  }