import { Image, useWindowDimensions } from "react-native";
import {
  Div,
  H1,
  H2,
  H3,
  H4,
  H5,
  P,
  Mark,
  Anchor,
  Section,
} from "./utils/tag.utils";
import { Page } from ".";

export const AboutUs = (props) => {
  const { width } = useWindowDimensions();
  const small = width < 520;
  return (
    <Page {...props}>
      <SectionHero></SectionHero>
      <Section1></Section1>
      <SectionCrew></SectionCrew>
      <Section2></Section2>
      <Technologies small={small}></Technologies>
    </Page>
  );
};

const SectionHero = (props) => (
  <Div className="bg-about-hero o-hidden">
    <Section className="text-white pb-0 pt-lg-2">
      <Div className="container pb-5 mt-4 mb-4">
        <Div className="row justify-content-center text-center">
          <Div className="col-md-9 col-lg-8 col-xl-7">
            <H1 className="display-3">
              <Mark
                data-aos="highlight-text"
                data-aos-delay="250"
                className="aos-init aos-animate"
              >
                Enigma
              </Mark>{" "}
              Key Industries
            </H1>
            <P className="lead mb-0">
              A group of technical, motivated and passionate <Mark>geeks</Mark>{" "}
              with a mission to deliver quality and secure products throughout
              the cosmos.
            </P>
          </Div>
        </Div>
      </Div>
      <Div
        className="position-absolute w-50 h-100 top left"
        // data-jarallax-element="100 50"
        style={{ transform: "translate3d(-5.40984px, -10.8197px, 0px)" }}
      >
        <Div className="bg-about-hero w-100 h-100 top left"></Div>
        <Div id="jarallax-container-0" style={{ zIndex: -100 }}>
          <Div style={{ position: "fixed" }}></Div>
        </Div>
      </Div>
      <Div className="Divider Divider-bottom bg-white"></Div>
    </Section>
  </Div>
);

const Section1 = (props) => (
  <Div
    className="container"
    style={{
      border: "10px solid white",
      borderRadius: "20% 30% 12% 40% / 33%",
      marginTop: -30,
      background: "white",
      paddingTop: 50,
      boxShadow: "inset 0 0 0 10px black",
      padding: 20,
      overflow: "hidden",
    }}
  >
    <Div className="row align-items-center justify-content-around text-center text-lg-left">
      <Div className="col-md-9 col-lg-6 col-xl-5 mb-4 mb-md-5 mb-lg-0 pl-lg-5 pl-xl-0">
        <Div>
          <H3 className="destined-to-change-web H1">
            Destined to Change The Web
          </H3>
          <P className="lead">
            Enigma Key Industries has a driven purpose to change the user
            experience of the web. Our true passion is making reliable, secure,
            and aestheticly pleasing digital products. Making a true impact for
            any business in the world of Web 3.0!
          </P>
        </Div>
      </Div>
      <Div
        className="col-md-9 col-lg-6 col-xl-5 text-right about-side-images-container"
        style={{
          minHeight: 400,
          borderRadius: "22% 44% 12% 33% / 63%",
          overflow: "hidden",
        }}
      >
        <Image
          source={{
            uri: require("../static/img/heros/about-first-image.png"),
          }}
          style={{
            minHeight: 400,
            borderRadius: "22% 44% 12% 33% / 33%",
            overflow: "hidden",
          }}
          className="about-side-images Image-fluid"
        />
      </Div>
    </Div>
  </Div>
);

const SectionCrew = (props) => {
  const { width } = useWindowDimensions();
  const small = width < 520;
  return (
    <Crew
      className={`about-team-container ${small && "small"}`}
      style={{
        zIndex: 9,
        background: "red",
        color: "white !important",
        boxShadow: "inset 0 0 0px 10px white, 0 0 0 10px red",
        border: "20px double white",
      }}
    >
      <CrewMember name="mike"></CrewMember>
      <CrewMember name="abdullah"></CrewMember>
      <CrewMember name="mikeT"></CrewMember>
      <CrewMember name="antonio"></CrewMember>
      <CrewMember name="amanda"></CrewMember>
      <CrewMember name="alyssa"></CrewMember>
    </Crew>
  );
};

const Section2 = (props) => (
  <Div
    className="container about-us-section-2"
    style={{
      border: "10px solid white",
      borderRadius: "20% 30% 12% 40% / 33%",
      marginTop: -30,
      background: "white",
      paddingTop: 50,
      boxShadow: "inset 0 0 0 10px black",
      padding: 20,
      overflow: "hidden",
    }}
  >
    <Div className="additonal-about-us-content row align-items-center justify-content-around text-center text-lg-left mb-lg-4">
      <Div className="col-md-9 col-lg-6 col-xl-5">
        <Image
          source={{
            uri: require("../static/img/heros/about-second-image.png"),
          }}
          style={{ minHeight: 400 }}
          className="about-side-images Image-fluid"
        />
      </Div>
      <Div className="col-md-9 col-lg-6 col-xl-5 mb-4 mb-md-5 mb-lg-0 pl-lg-5 pl-xl-0">
        <Div className="mt-4">
          <H3 className="highly-skilled-inDividuals H1">
            Highly Skilled and Diverse InDividuals
          </H3>
          <P className="lead">
            Our crew specializes in all forms of software development and
            design. We have many years of unique experience in the field. You
            can experience the crew's passion through our work.
          </P>
          <Anchor href="#" className="lead">
            Let's Chat About Your Project
          </Anchor>
        </Div>
      </Div>
    </Div>
  </Div>
);

const Technologies = ({ small }) => (
  <Section
    className="bg-primary-3 text-white"
    style={{
      margin: "-40px 40px 0",
      borderRadius: "18% 28% 34% 42% / 33%",
      transform: small ? '' : "skew(7.5deg, 5deg)",
    }}
  >
    <Div
      className="container"
      style={{ paddingTop: 0, transform: small ? '' : "skew(-7.5deg, -5deg)" }}
    >
      <Div className="row our-tech-title Section-title justify-content-center text-center">
        <Div className="col-md-9 col-lg-8 col-xl-7">
          <H3 className="display-4">Our Technlogies</H3>
          <Div className="lead">
            We have a selected list of skillsets and tools for getting the job
            done correctly. These are the latest technoligies that we have
            mastered.
          </Div>
        </Div>
      </Div>
      <Div className="row justify-content-center">
        <Div className="col d-flex flex-wrap justify-content-center">
          {[
            "Ruby on Rails",
            "React JS",
            "React Native",
            "AngularJS",
            "Adobe Creative Suite",
            "Sketch",
            "Figma",
            "Next.js",
            "Vue.js",
            "Express.js",
            "Python",
            "Django",
            "Flutter",
            "Java",
            "Ruby",
            "Javascript",
            "HTML",
            "CSS",
            "Bootstrap",
          ].map((tech) => (
            <Div key={tech} className="m-2 text-dark">
              <Div className="media rounded align-items-center pl-3 pr-4 pl-md-4  py-2 bg-white text-dark text-center">
                <H5 className="text-center mb-0">{tech}</H5>
              </Div>
            </Div>
          ))}
        </Div>
      </Div>
    </Div>
  </Section>
);

const Crew = ({ className, children, style }) => (
  <Div id="meet-the-crew" className={`container ${className}`} style={style}>
    <Div className="row Section-title justify-content-center text-center">
      <Div className="col-md-9 col-lg-8 col-xl-7">
        <Div className="about-team-introuction-container">
          <H3 className="display-4">Meet The Crew</H3>
          <Div className="lead">
            Our crew is one of the best in the field. We aren't here to brag but
            just take great pride in our talented work.
          </Div>
        </Div>
      </Div>
    </Div>
    <Div className="row justify-content-center">
      <Div className="col-sm-12 mt-lg-5">
        <Div className="row justify-content-center">
          <Div className="col-sm-12 mt-lg-5"></Div>
          {children}
        </Div>
      </Div>
    </Div>
  </Div>
);

const Members = {
  mike: {
    image: {
      source: { uri: require("../static/img/avatars/mike.png") },
      alt: "Michael Simoneau profile photo",
    },
    name: "Michael Simoneau",
    title: "Chief Executive Officer",
    linkedIn: "MichaelSimoneau",
  },
  mikeT: {
    image: {
      source: { uri: require("../static/img/avatars/mike_thomas.png") },
      alt: "Michael Thomas profile photo",
    },
    name: "Michael Thomas",
    title: "Chief Sales Officer",
    linkedIn: "thomas-michael-d",
  },
  antonio: {
    image: {
      source: {
        uri: require("../static/img/avatars/tony.png"),
      },
      alt: "Antonio Irizarry profile image",
    },
    name: "Antonio Irizarry",
    title: "President of Design",
    linkedIn: "antonio-irizarry",
  },
  abdullah: {
    image: {
      source: { uri: require("../static/img/avatars/abdullah.png") },
      alt: "Abdullah Faisal profile image",
    },
    name: "Abdullah Faisal",
    title: "Chief Operations Officer",
    linkedIn: "muhammad-abdullah-faisal-a297991a2",
  },
  amanda: {
    image: {
      source: { uri: require("../static/img/avatars/amanda_head_shot.png") },
      alt: "Amanda Mestrovich profile image",
      style: { width: 100, height: 100 },
    },
    name: "Amanda Mestrovich",
    title: "Project Manager",
    linkedIn: "amanda-mestrovich-2718a240",
  },
  alyssa: {
    image: {
      source: { uri: require("../static/img/avatars/bunty.png") },
      alt: "Hamza Ehsan profile photo",
    },
    name: "Hamza Ehsan",
    title: "Software Engineer",
    linkedIn: "hamza-e-56937113b",
  },
};

const CrewMember = ({ name, style }) => {
  const { width } = useWindowDimensions();
  const small = width < 520;
  return (
    <Div
      className="row mb-5 col-sm-4"
      style={{ alignSelf: "flex-start", flexDirection: "row" }}
    >
      <Div className="col-md-4 col-sm-6 text-center mb-5"></Div>
      <Div
        className="col-md-4 col-sm-6 text-center mb-5"
        style={{ position: "relative", ...style }}
      >
        <Div className="d-flex flex-column align-items-center">
          <Image
            {...Members[name].image}
            style={{ width: 100, height: 100, borderRadius: 50 }}
            className="avatar avatar-xl mb-4"
          />
          <H5 className="mt-3 mb-2 text-nowrap">{Members[name].name}</H5>
          <Div className="mt-2 mb-3 text-nowrap">{Members[name].title}</Div>
          <Anchor
            target="_blank"
            href={`https://www.linkedin.com/in/${Members[name].linkedIn}/`}
            className="about-linked-in-icon-link hover-fade-out"
            style={{
              position: "absolute",
              top: 70,
              right: small ? 40 : -40,
            }}
          >
            <Image
              source={{
                uri: require("../static/img/icons/social/linked-in-icon.png"),
              }}
              style={{
                width: 40,
                height: 40,
              }}
              className="about-linked-in-icon"
              alt="Dribbble"
            />
          </Anchor>
        </Div>
      </Div>
    </Div>
  );
};
