import axios from "axios";
import { useEffect } from "react";
import { useColorScheme, useWindowDimensions } from "react-native";
import { Div, H3 } from "../utils/tag.utils";

export const DiscordWidget = ({ pageRef, named, small }) => {
  const theme = useColorScheme();
  const { width, height } = useWindowDimensions();
  const widgetWidth = width * (small ? 0.9 : 0.4);

  useEffect(() => {
    setTimeout(() => {
      if (!`${window.location}`.match(/applicants/gi)) return;
      pageRef.current &&
        pageRef.current?.scrollTo &&
        pageRef.current?.scrollTo({
          y: pageRef.current?.height - height / 2
        });
      console.log("RAN TIMEOUT", pageRef.current.scrollTop);
    }, 500);
  }, [window.location, pageRef.current]);

  const wrapperStyle = {
    display: "inline-block",
    width: "auto",
    minWidth: small ? "100%" : "40%",
  };

  switch (named) {
    case "applicants":
      return (
        <Div className="col-6 col-md-12 col-sm-12" style={wrapperStyle}>
          <H3>Enigma Applicants:</H3>
          <iframe
            style={{ margin: "0 20px", filter: "hue-rotate(120deg)" }}
            src={`https://discord.com/widget?id=1008153687983652904&theme=${theme}`}
            width={widgetWidth}
            height="300"
            allowtransparency="true"
            frameBorder={0}
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          ></iframe>
        </Div>
      );
    default:
      return (
        <Div
          id="!/Contact/Applicants"
          className="col-6 col-md-12 col-sm-12"
          style={wrapperStyle}
        >
          <H3>Enigma Employees:</H3>
          <iframe
            style={{ margin: "0 20px", filter: "hue-rotate(120deg)" }}
            src={`https://discord.com/widget?id=997226477999378512&theme=${theme}`}
            width={widgetWidth}
            height="300"
            allowtransparency="true"
            frameBorder={0}
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          ></iframe>
        </Div>
      );
  }
};
