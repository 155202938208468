import { Div } from "../utils/tag.utils";
import { Widget } from "../Widget";
import { DiscordWidget } from "./discord.widget";

export const DiscordWidgetGroup = ({ small, pageRef }) => {
  return (
    <Div style={{ width: "100%", textAlign: "center", marginBottom: "4rem" }}>
      <Widget
        pageRef={pageRef}
        small={small}
        component={DiscordWidget}
        named="default"
        style={{ marginHorizontal: 20, marginBottom: '3rem' }}
        />
      <Widget
        pageRef={pageRef}
        small={small}
        component={DiscordWidget}
        named="applicants"
        style={{ marginHorizontal: 20, marginBottom: '3rem' }}
      />
    </Div>
  );
};
