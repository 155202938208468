import React from "react";
import { Blockquote, H3, Section } from "../utils/tag.utils";

export const Quote = ((props) => {
  return (
    <Section
      class="blockquote mt-2 mb-0 text-center"
      style={{
        zIndex: 2,
        background: "black",
        borderRadius: "42% 34% 32% 18% / 33%",
        padding: "3.75rem 4.4rem",
        transform: "skew(-3deg, 7deg) scaleY(0.9)",
        boxShadow: "white 0px -10px 0px 10px, white 0px 10px 0px 10px",
        maxWidth: "960px",
        margin: "2rem auto"
      }}
    >
      <H3
        class="mt-2 mb-2aos-init aos-animate aos-init"
        data-aos="fade-up"
        data-aos-delay="40"
        style={{ transform: "skew(3deg, -7deg)", color: "white", fontSize: '2.6rem' }}
      >
        {props.children}
      </H3>
      <Blockquote style={{ transform: "skew(3deg, -7deg)", textAlign: 'right', fontSize: '1.6rem' }}>
        - {props.by}.
      </Blockquote>
    </Section>
  );
});
