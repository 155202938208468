import { Text as HTMLText, View as HTMLView } from "react-native-web";
import {
  Button,
  Image as NativeImage,
  Linking,
  Platform,
  Text as NativeText,
  TouchableOpacity,
  useWindowDimensions,
  View as NativeView,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { forwardRef, useRef } from "react";
import axios from "axios";

export const isWeb = Platform.OS === "web";

export const SafeView = forwardRef((props: any, ref: any) =>
  isWeb ? (
    <HTMLView
      ref={ref}
      className={props.className}
      style={props.style}
      tabIndex={props.tabIndex}
    >
      {props.children}
    </HTMLView>
  ) : (
    <NativeView
      ref={ref}
      {...{
        className: props.className,
        tabIndex: props.tabIndex,
      }}
      style={{ ...props.style, ...props.nativeStyle }}
    >
      {props.children}
    </NativeView>
  )
);

export const SafeText: any = forwardRef((props: any, ref: any) =>
  isWeb ? (
    <HTMLText
      ref={ref}
      className={props.className}
      style={{ ...props.style, ...(isWeb || props.nativeStyle) }}
      tabIndex={props.tabIndex}
    >
      {props.children}
    </HTMLText>
  ) : (
    <NativeText
      ref={ref}
      {...{
        className: props.className,
        tabIndex: props.tabIndex,
      }}
      style={{ ...props.style, ...props.nativeStyle }}
    >
      {props.children}
    </NativeText>
  )
);

export const ScrollView = forwardRef((props: any, ref: any) =>
  isWeb ? (
    <div
      ref={ref}
      style={{
        overflowX: "hidden",
        overflowY: "scroll",
        height: "100%",
        ...props.stlye,
      }}
    >
      {props.children}
    </div>
  ) : (
    <KeyboardAwareScrollView
      ref={ref}
      style={{ ...props.style, ...(isWeb || props.nativeStyle) }}
    >
      {props.children}
    </KeyboardAwareScrollView>
  )
);

export const Mark = forwardRef((props: any, ref: any) =>
  isWeb ? (
    <mark
      ref={ref}
      className={props.className}
      style={{ ...props.style, ...(isWeb || props.nativeStyle) }}
    >
      {props.children}
    </mark>
  ) : (
    <SafeText
      ref={ref}
      className={props.className}
      style={{ ...props.style, ...(isWeb || props.nativeStyle) }}
    >
      {props.children}
    </SafeText>
  )
);

export const Div: any = forwardRef((props: any, ref: any) =>
  isWeb ? (
    <div
      ref={ref}
      className={props.className}
      style={{ ...props.style, ...(isWeb || props.nativeStyle) }}
      tabIndex={props.tabIndex}
    >
      {props.children}
    </div>
  ) : (
    <SafeText
      ref={ref}
      className={props.className}
      style={{ ...props.style, ...(isWeb || props.nativeStyle) }}
      tabIndex={props.tabIndex}
    >
      {props.children}
    </SafeText>
  )
);

export const DivAbs = forwardRef((props: any, ref: any) => (
  <Div
    ref={ref}
    className={props.className}
    style={{
      // position: "absolute",
      // width: "100%",
      // height: "100%",
      ...props.style,
    }}
  >
    {props.children}
  </Div>
));

export const Span = forwardRef((props: any, ref: any) =>
  isWeb ? (
    <span
      ref={ref}
      className={props.className}
      style={{ ...props.style, ...(isWeb || props.nativeStyle) }}
      tabIndex={props.tabIndex}
    >
      {props.children}
    </span>
  ) : (
    <SafeText
      ref={ref}
      className={props.className}
      style={{ ...props.style, ...(isWeb || props.nativeStyle) }}
      tabIndex={props.tabIndex}
    >
      {props.children}
    </SafeText>
  )
);

export const Section = forwardRef((props: any, ref: any) => {
  const section = isWeb ? (
    <section
      ref={ref}
      onClick={props.onPress}
      className={props.className}
      style={{ ...props.style, ...(isWeb || props.nativeStyle) }}
      tabIndex={props.tabIndex}
    >
      {props.children}
    </section>
  ) : (
    <SafeText
      ref={ref}
      className={props.className}
      style={{ ...props.style, ...(isWeb || props.nativeStyle) }}
      tabIndex={props.tabIndex}
    >
      {props.children}
    </SafeText>
  );
  const touchRef = useRef(null);
  return props.onPress ? (
    <TouchableOpacity
      ref={touchRef}
      style={{ zIndex: props.style.zIndex }}
      onPress={($event) => {
        $event.preventDefault();
        touchRef.current.style.opacity = 0.5;
        props.onPress();
        touchRef.current.style.opacity = 1;
      }}
    >
      {section}
    </TouchableOpacity>
  ) : (
    section
  );
});

// function useWindowDimensions() {
//   const [windowDimensions, setWindowDimensions] = useState({
//     width: Dimensions.get("window").width,
//     height: Dimensions.get("window").height,
//   });

//   useEffect(() => {
//     const onChange = ({ window }) => {
//       setWindowDimensions({
//         width: window.width,
//         height: window.height,
//       });
//     };

//     Dimensions.addEventListener("change", onChange);

//     return () => Dimensions.removeEventListener("change", onChange);
//   }), [windowDimensions];

//   return windowDimensions;
// }

// export const useWindowWidth = () => {
//   const { width } = useWindowDimensions();
//   return width;
// }

// export const useWindowHeight = () => {
//   const { height } = useWindowDimensions();
//   return height;
// }

export const Image = forwardRef((props: any, ref: any) => {
  return isWeb ? (
    <img
      ref={ref}
      {...props}
      src={(props.source && props.source.uri) || props.source}
    />
  ) : (
    <NativeImage
      ref={ref}
      {...{
        className: props.className,
        tabIndex: props.tabIndex,
      }}
      style={{
        ...props.style,
        ...(isWeb || props.nativeStyle),
      }}
      source={props.source}
    />
  );
});

export const Anchor = forwardRef((props: any, ref: any) => {
  const { width } = useWindowDimensions();
  return isWeb ? (
    <TouchableOpacity
      onPress={
        typeof props.href !== "undefined"
          ? () => Linking.openURL(props.href)
          : props.onPress
      }
      ref={ref}
      // href={props.href}
      style={{
        ...props.style,
        ...props.nativeStyle,
        borderRadius: 30,
      }}
    >
      <SafeText
        tabIndex={props.tabIndex}
        className={props.className}
        style={props.textStyle}
      >
        {props.children}
      </SafeText>
    </TouchableOpacity>
  ) : (
    <TouchableOpacity
      ref={ref}
      onPress={
        typeof props.href !== "undefined"
          ? () => Linking.openURL(props.href)
          : props.onPress
      }
      style={{ ...props.style, ...(isWeb || props.nativeStyle) }}
    >
      <SafeText className={props.className} style={props.textStyle}>
        {props.children}
      </SafeText>
    </TouchableOpacity>
  );
});

export const H1 = forwardRef((props: any, ref: any) =>
  isWeb ? (
    <h1
      ref={ref}
      className={[props.className, "h1"].join(" ")}
      style={{ ...props.style, ...(isWeb || props.nativeStyle) }}
      tabIndex={props.tabIndex}
    >
      {props.children}
    </h1>
  ) : (
    <SafeText
      ref={ref}
      className={props.className}
      style={{ ...props.style, ...(isWeb || props.nativeStyle) }}
      tabIndex={props.tabIndex}
    >
      {props.children}
    </SafeText>
  )
);

export const H2 = forwardRef((props: any, ref: any) =>
  isWeb ? (
    <h2
      ref={ref}
      className={[props.className, "h2"].join(" ")}
      style={{ ...props.style, ...(isWeb || props.nativeStyle) }}
      tabIndex={props.tabIndex}
    >
      {props.children}
    </h2>
  ) : (
    <SafeText
      ref={ref}
      className={props.className}
      style={{ ...props.style, ...(isWeb || props.nativeStyle) }}
      tabIndex={props.tabIndex}
    >
      {props.children}
    </SafeText>
  )
);

export const H3 = forwardRef((props: any, ref: any) =>
  isWeb ? (
    <h3
      ref={ref}
      className={[props.className, "h3"].join(" ")}
      style={{ ...props.style, ...(isWeb || props.nativeStyle) }}
      tabIndex={props.tabIndex}
    >
      {props.children}
    </h3>
  ) : (
    <SafeText
      ref={ref}
      className={props.className}
      style={{ ...props.style, ...(isWeb || props.nativeStyle) }}
      tabIndex={props.tabIndex}
    >
      {props.children}
    </SafeText>
  )
);

export const H4 = forwardRef((props: any, ref: any) =>
  isWeb ? (
    <h4
      ref={ref}
      className={[props.className, "h4"].join(" ")}
      style={{ ...props.style, ...(isWeb || props.nativeStyle) }}
      tabIndex={props.tabIndex}
    >
      {props.children}
    </h4>
  ) : (
    <SafeText
      ref={ref}
      className={props.className}
      style={{ ...props.style, ...(isWeb || props.nativeStyle) }}
      tabIndex={props.tabIndex}
    >
      {props.children}
    </SafeText>
  )
);

export const H5 = forwardRef((props: any, ref: any) =>
  isWeb ? (
    <h5
      ref={ref}
      className={[props.className, "h5"].join(" ")}
      style={{ ...props.style, ...(isWeb || props.nativeStyle) }}
      tabIndex={props.tabIndex}
    >
      {props.children}
    </h5>
  ) : (
    <SafeText
      ref={ref}
      className={props.className}
      style={{ ...props.style, ...(isWeb || props.nativeStyle) }}
      tabIndex={props.tabIndex}
    >
      {props.children}
    </SafeText>
  )
);

export const H6 = forwardRef((props: any, ref: any) =>
  isWeb ? (
    <h6
      ref={ref}
      className={[props.className, "h6"].join(" ")}
      style={{ ...props.style, ...(isWeb || props.nativeStyle) }}
      tabIndex={props.tabIndex}
    >
      {props.children}
    </h6>
  ) : (
    <SafeText
      ref={ref}
      className={props.className}
      style={{ ...props.style, ...(isWeb || props.nativeStyle) }}
      tabIndex={props.tabIndex}
    >
      {props.children}
    </SafeText>
  )
);

export const P = forwardRef((props: any, ref: any) =>
  isWeb ? (
    <p
      ref={ref}
      className={props.className}
      style={{ ...props.style, ...(isWeb || props.nativeStyle) }}
      tabIndex={props.tabIndex}
    >
      {props.children}
    </p>
  ) : (
    <SafeText
      ref={ref}
      className={props.className}
      style={{ ...props.style, ...(isWeb || props.nativeStyle) }}
      tabIndex={props.tabIndex}
    >
      {props.children}
    </SafeText>
  )
);

export const Blockquote = forwardRef((props: any, ref: any) =>
  isWeb ? (
    <blockquote
      ref={ref}
      className={props.className}
      style={{ ...props.style, ...(isWeb || props.nativeStyle) }}
      tabIndex={props.tabIndex}
    >
      {props.children}
    </blockquote>
  ) : (
    <SafeText
      ref={ref}
      className={props.className}
      style={{ ...props.style, ...(isWeb || props.nativeStyle) }}
      tabIndex={props.tabIndex}
    >
      {props.children}
    </SafeText>
  )
);

export const Form = forwardRef((props: any, ref: any) => {
  const onSubmit = async ($event: Event) => {
    if (props.ajax) {
      $event.preventDefault();
      console.warn($event);
      const response = await axios[props.ajax.method.toLowerCase() || "get"](
        props.ajax.action,
        props.data
      );
      if (props.onSubmit) props.onSubmit(response);
      return false;
    } else {
      console.warn("submitting old school (add 'ajax' object if unintended)");
    }
  };
  return isWeb ? (
    <form
      ref={ref}
      {...props}
      style={{ ...props.style, ...(isWeb || props.nativeStyle) }}
      method={props.method || "GET"}
      onSubmit={onSubmit}
    >
      {props.children}
    </form>
  ) : (
    <SafeView
      ref={ref}
      className={props.className}
      style={{ ...props.style, ...(isWeb || props.nativeStyle) }}
      tabIndex={props.tabIndex}
    >
      {props.children}
      <Button
        title={props.submit}
        {...{ className: props.buttonClassName }}
        onPress={() => {
          const event = new Event("submit");
          ref.current?.dispatchEvent(event);
          onSubmit(event);
        }}
      >
        {props.submit}
      </Button>
    </SafeView>
  );
});
