import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  ScrollView,
  Platform,
  SafeAreaView,
  useWindowDimensions,
  StyleProp,
  ViewStyle,
} from "react-native";

import {
  NavigationContainer,
  NavigationProp,
  ParamListBase,
  RouteProp,
  StackActions,
  StackNavigationState,
  TypedNavigator,
  useNavigation,
} from "@react-navigation/native";
import {
  createStackNavigator,
  StackHeaderProps,
  StackNavigationEventMap,
  StackNavigationOptions,
  StackNavigationProp,
} from "@react-navigation/stack";

import { Anchor, Div, H1, P, SafeView as View } from "./utils/tag.utils";

// Sections & Components
import { Sections } from "./Sections";
import { CaseStudyIndex } from "./CaseStudies/CaseStudyIndex";

// Widgets
import { Widget } from "./Widget";
import { CalendlyWidget } from "./widgets/calendly.widget";

// Pages
import { Pages } from "./pages";
import { Contact } from "./contact/Contact";
import { AboutUs } from "./About";
import { Header } from "./Header";
import { CaseStudies, CaseStudy } from "./CaseStudies/CaseStudies.const";
import { A_404, CaseStudiesDetail } from "./CaseStudies/CaseStudiesDetail";
import { Breakpoints } from "./consts/breakpoints.const";
import { Services } from "./sections/Services.const";
import breakpointsConst from "./consts/breakpoints.const";
import { ServicesSection } from "./sections/Services";
import { formatUrl } from "./utils/utils";

import { ServicesDetailPage } from "./sections/ServicesDetails";
import { Home } from "./Home";

const AppContext = createContext({});

export interface AppContext {
  initialRouteName: Pages | string;
  setInitialRouteName: Dispatch<SetStateAction<Pages | string>>;
  linking: { prefixes: string[]; config: { screens: any } };
  setLinking: Dispatch<
    SetStateAction<{ prefixes: any[]; config: { screens: any } }>
  >;
  height: number;
  width: number;
  breakpoints: Breakpoints<boolean>;
  isWeb: boolean;
  pageRef: React.RefObject<HTMLElement | ScrollView>;
  headerRef: React.RefObject<HTMLElement | ScrollView>;
  AppContext: any;
}

export interface HeaderProps extends StackHeaderProps {}
export interface HeaderState extends AppContext, HeaderProps {}
export interface ScreenProps {
  route: RouteProp<ParamListBase, string | Pages>;
  navigation: StackNavigationProp<
    ParamListBase,
    string | Pages,
    any | undefined
  >;
}
export interface ScreenState extends AppContext, ScreenProps {}

export const Index = () => {
  const { Navigator, Screen } = createStackNavigator();
  const pageRef = useRef(null);
  const headerRef = useRef(null);

  const isWeb = Platform.OS === "web";
  const { width, height } = useWindowDimensions();

  const [linking, setLinking] = useState({
    prefixes: [],
    config: {
      screens: {
        Home: Pages.HomePage,
        Contact: Pages.Contact,
        AboutUs: Pages.AboutUs,
        CaseStudies: Pages.CaseStudies,
        ...Object.fromEntries(
          CaseStudies.map((item) => Array(2).fill(formatUrl(item.link)))
        ),
        Services: Pages.Services,
        ...Object.fromEntries(
          Services.services
            .map((item) =>
              !item.link ? null : Array(2).fill(formatUrl(item.link))
            )
            .filter(Boolean)
        ),
      },
    },
  });

  const breakpoints = breakpointsConst.getBreakpoints(width);

  const [initialRouteName, setInitialRouteName] = useState(
    formatUrl(window.location.pathname)
  );

  const [state, setState]: [AppContext, Dispatch<SetStateAction<AppContext>>] =
    useState({
      initialRouteName,
      setInitialRouteName,
      linking,
      setLinking,
      breakpoints,
      width,
      height,
      isWeb,
      pageRef,
      headerRef,
      AppContext,
    });

  const appState = [state, setState];

  useEffect(() => {
    setState({
      ...state,
      width,
      breakpoints: breakpointsConst.getBreakpoints(width),
    });
  }, [width]);

  useEffect(() => {
    setState({
      ...state,
      height,
    });
  }, [height]);

  return (
    <AppContext.Provider value={appState}>
      <NavigationContainer linking={linking} fallback={<A_404 />}>
        <Navigator
          initialRouteName={initialRouteName}
          screenOptions={{
            header: (props) => <Header {...props} {...state} />,
            cardStyle: {
              flex: 1,
              overflow: "scroll",
              ...{ overflowX: "hidden" },
              maxWidth: width,
            },
          }}
        >
          <Screen name={Pages.HomePage} component={Home} />
          <Screen name={Pages.Contact} component={ContactPage} />
          <Screen name={Pages.AboutUs} component={AboutUs} />
          <Screen name={Pages.CaseStudies} component={CaseStudiesPage} />
          {CaseStudies.map((caseStudy) => (
            <Screen key={caseStudy.tag} name={formatUrl(caseStudy.link)}>
              {(props: ScreenProps) => (
                <Page {...props}>
                  <CaseStudiesDetailPage caseStudy={caseStudy} />
                </Page>
              )}
            </Screen>
          ))}
          <Screen name={Pages.Services} component={ServicesPage} />
          {Services.services.map((service) => (
            <Screen key={service.title} name={formatUrl(service.link)}>
              {(props: ScreenProps) => (
                <Page {...props}>
                  <ServicesDetailPage service={service} />
                </Page>
              )}
            </Screen>
          ))}
        </Navigator>
      </NavigationContainer>
    </AppContext.Provider>
  );
};

interface PageProps extends ScreenProps {
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}

export const Page = (
  {children, ...props}: PageProps
) => {
  try {
    const [state, setState]:
      | [AppContext, Dispatch<SetStateAction<AppContext>>]
      | any = React.useContext(AppContext);
    let childrenWithProps: React.ReactNode[] = [];
    if (Array.isArray(children)) {
      children.forEach((child) => {
        if (React.isValidElement(child)) {
          childrenWithProps.push(
            React.cloneElement(child as React.ReactElement, {
              ...state,
              ...props,
            })
          );
        }
      });
    } else if (React.isValidElement(children)) {
      childrenWithProps = [children];
    } else {
      return null;
    }
    console.log("Page", props.route.name);
    console.log("children", childrenWithProps);
    if (Array.isArray(childrenWithProps)) {
      childrenWithProps = React.Children.map(children, (child) => {
        return React.cloneElement(child as any, { ...state, ...props });
      }).filter((child) => child);
      return (
        <SafeAreaView style={props.style} ref={state.pageRef}>
          {childrenWithProps}
        </SafeAreaView>
      );
    } else if (childrenWithProps) {
      return (
        <SafeAreaView style={props.style} ref={state.pageRef}>
          {React.cloneElement(childrenWithProps as any, { ...state, ...props })}
        </SafeAreaView>
      );
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
    return <A_404 />;
  }
};

const ContactPage = (props: ScreenProps) => (
  <Page {...props}>
    <Contact />
  </Page>
);

const CaseStudiesPage = (props) => (
  <Page {...props}>
    <CaseStudyIndex {...props} />
  </Page>
);
const CaseStudiesDetailPage = ({
  caseStudy,
  ...props
}: {
  caseStudy: CaseStudy;
}) => (
  <Page {...(props as ScreenProps)}>
    <CaseStudiesDetail caseStudy={caseStudy} />
  </Page>
);
const ServicesPage = (props: ScreenProps) => (
  <Page {...props}>
    <ServicesSection {...(props as ScreenState)} />
  </Page>
);
