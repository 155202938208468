import { ImageSourcePropType } from "react-native";
import { Div, H2, H3, H5, P, Image } from "../utils/tag.utils";

export interface CaseStudy {
  title: string;
  description: string;
  image: ImageSourcePropType;
  banner: string;
  link: string;
  hashtags: string[];
  tag: string;
  details: {
    menu: { [key: string]: string | { url: string; text: string } };
    title: string;
    quote: string;
    article: string | React.ReactNode;
  };
}

export const CaseStudies: CaseStudy[] = [
  {
    title: "YachtOffice",
    description:
      "An AI Trading Platform that automatically learns as it trades autonomously on a DeFi network.",
    image: require("../../static/img/logos/yacht-office.png"),
    banner: require("../../static/img/case-studies/crypto-trading-bot.png"),
    tag: "Generative AI Trading Platform",
    hashtags: ["Python", "Javascript", "ReactJS", "ReactNative", "TradingView"],
    link: "/case-studies/yachtoffice",
    details: {
      quote: "Make your money make money while you're not making money",
      title: "YachtOffice",
      menu: {
        Company: "Enigma Key Industries",
        Location: "Chicago, IL",
        Industry: "Cryptocurrency Trading",
        Size: "2-50 employees",
        "Project Type": "Generative AI, TradingView API, ReactJS, ReactNative",
        Website: {
          url: "https://yachtoffice.me/",
          text: "yachtoffice.me",
        },
      },
      article: (
        <article className="article">
          <H2>YachtOffice from Enigma Key Industries</H2>
          <P>
            YachtOffice is an AI trading platform that uses AI and Machine Learning to automatically learn and trade by allowing users to select simple risk profiles on the Web3 Decenteralized Network.
          </P>
          <Div style={{backgroundColor: "black"}} className="dscpa-factory-image-container">
            <Image source={require("../../static/img/case-studies/thumb-4.jpg")} />
          </Div>
          <H3 className="mt-4">Web3 Platform</H3>
          <P>
            The YachtOffice is a platform where users enter into public or private pools to contribute collectively to a fund (ERC-20). This fund is then used according to the pools’ simple-to-configure risk profile to finance futures/options contract orders autonomously, with AI assisted quantitative real-time-data analysis, algorithmic prediction models, and qualitative Proof of Work inspired validators; all operating on a decentralized network to bring investment opportunities and returns like never seen before!
          </P>
          <P>
            The YachtOffice is able to, through the use of Artificial Intelligence and Blockchain technologies generate calculated investments with fully automated risk management and autonomous identification and execution of trades to produce extremely high return on investment yield in periods as short as just a few days, reaching returns of over 25% per day on average.
          </P>
          <Div style={{backgroundColor: "black"}} className="dscpa-factory-image-container">
            <Image source={require("../../static/img/case-studies/large-1.jpg")} />
          </Div>
          <H3 className="mt-4">Welcome to the Bacht® Club</H3>
          <P>
            The Bacht® is ingeniously designed to be a pragmatic, impartial and unbiased guardian that concurrently scans the network for all relevant confirmations and will always conclude in the most mathematically and scientifically appropriate manner, based on a complete history of each YachtOffice account.
          </P>
          <Div style={{backgroundColor: "black"}} className="dscpa-factory-image-container">
            <Image source={require("../../static/img/case-studies/crypto-trading-bot.png")} />
          </Div>
          <H3 className="mt-4">Financial Utopia</H3>
          <P>
            Imagine a world where you never have to worry about the bills piling up or an unexpected expense. Where money isn’t only for the wealthy but also distributed to any group using The YachtOffice. Members can pool with other “Bacht Heads” they know or even join a public pool, organization or charity! Together, through the power of AI and Blockchain, we have the power, and moreover the duty to enrich, educate and empower the world’s people to live life to a greater potential; to engage our minds and challenge the boundaries of what is truly possible!!
          </P>
          <Div style={{backgroundColor: "black"}} className="dscpa-factory-image-container">
            <Image source={require("../../static/img/case-studies/thumb-5.jpg")} />
          </Div>
        </article>
      ),
    },
  },
  {
    title: "Akadimia",
    description:
      "Akadimia is the world’s first artificial intelligence library. It brings sculptures and influential people back to life with the help of AR.",
    image: require("../../static/img/case-studies/akadimia.png"),
    banner: require("../../static/img/case-studies/akadimia-hero.png"),
    tag: "Artificial Intelligence Encyclopedia",
    hashtags: [
      "Unity",
      "Metaverse",
      "Augmented Reality",
      "Swift",
      "Java",
      "Obj-C",
    ],
    link: "/case_studies/akadimia",
    details: {
      title: "Akadimia",
      quote:
        "Innovating the future of learning through technology, art, and science",
      menu: {
        Company: "Akadimia",
        Location: "Chicago, Illinois",
        Industry: "Artificial Intelligence",
        Size: "2-50 employees",
        "Project Type": "API, Augmented Reality, SDK",
        Website: {
          url: "https://www.akadimia.ai/",
          text: "www.akadimia.ai",
        },
      },
      article: (
        <article className="article">
          <H2>Using Artificial Intelligence to educate through Web 3.0</H2>
          <P>
            Akadimia is a platform that enables users to speak to the digital
            version of influential figures in history. Akadimia is creating
            digital versions of influential figures that shaped history, whether
            it is a scientist, explorer, businessman, entrepreneur, inventor or
            king! you will be able to learn about them through.
          </P>
          <Div class="akadimia-image-container mb2">
            <Image
              style={{ maxWidth: "90%", margin: "20px 5% 40px" }}
              source={require("../../static/img/case-studies/akadimia-description-screenshots.png")}
            />
          </Div>
          <H3 class="h2 mt2">Conversational AI</H3>
          <P>
            Applied Conversational Al requires both science and art to create
            successful applications that incorporate context, personalization
            and relevance within human to computer interaction. Our team has
            developed a unique Conversational Al that can communicate like a
            human by recognizing speech and text, understanding intent, and
            responding in a way that mimics human conversation.
          </P>
          <H3>Augmented Reality</H3>
          <P>
            AR consists of layering digitally created elements - Akadimia
            Characters - over the real world in for of the user. The interactive
            nature of this technology makes it an ideal fit for accessing and
            providing information and resources for students to engage with - on
            their own terms. In the near future, with the arrival of AR wearable
            devices and 5G technology, the user's experience with Akadimia will
            be a revolutionarily immersive experience which will bring learning,
            education and discovery to a whole new level.
          </P>
        </article>
      ),
    },
  },
  {
    title: "Ecovative Design",
    description:
      "Built an Enterprise Resource Planning System to help automate operations for the warehouse and manufacturing.",
    image: require("../../static/img/logos/ecovative-design-logo.png"),
    banner: require("../../static/img/case-studies/ecovative/ecovative-design-video-cover.png"),
    tag: "Enterprise Resource Planning System",
    hashtags: ["API Development", "Javascript", "Ruby", "Python", "Obj-C"],
    link: "/case_studies/ecovative_design",
    details: {
      title: "Ecovative Design",
      quote: "Are mushrooms the new plastic?",
      menu: {
        Company: "Ecovative Design",
        Location: "Green Island, New York",
        Industry: "Biotechnology Research",
        Size: "51-200 employees",
        "Project Type": "SaaS ERP, Automation",
        Website: {
          url: "https://www.ecovative.com/",
          text: "www.ecovative.com",
        },
      },
      article: (
        <article className="article">
          <H2 className="h2">A Company Changing The World With Mushrooms</H2>
          <P>
            Ecovative Design is a materials company located in Upstate New York
            that provides sustainable alternatives to plastics and polystyrene
            foams. With their mushroom technology ecovative makes packaging,
            building materials and other applications.
          </P>
          <br />
          <Div className="ecovative-video-player-container">
            <Div style={{ maxWidth: "600px" }}>
              <Div
                style={{
                  position: "relative",
                  height: 0,
                  paddingBottom: "56.25%",
                }}
              >
                <iframe
                  src="https://embed.ted.com/talks/lang/en/eben_bayer_are_mushrooms_the_new_plastic"
                  width="854"
                  height="480"
                  scrolling="no"
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  {...{
                    frameBorder: 0,
                    allowFullScreen: true,
                  }}
                ></iframe>
              </Div>
            </Div>
            {/* <Image
              {...{ className: "ecovative-video-player-cover" }}
              source={require("../../static/img/case-studies/ecovative/ecovative-design-video-cover.png")}
            /> */}
            <Div className="ecovative-video-stats">
              TEDGlobal 2010 <span style={{ color: "red" }}>&#8857;</span> July
              2010 | 1.3 Million Views
            </Div>
          </Div>
          <H5>Increasing Productivity through Automation</H5>
          <P>
            At Ecovative's headquater and warehouse there are many departments
            with employees whom have different roles and responsibilities. With
            no legacy system in place, Ecovative had a great opportunity to sync
            the data across these departments into a uniform information system.
            To remove confusion, manual errors, and to reduce company overhead
            we decided to build an internal ERP system.
          </P>
          <Div className="ecovative-factory-image-container">
            <Image
              source={{
                uri: "https://www.vnews.com/getattachment/3cd7d64d-eae9-416f-a28e-d7323fc599ad/c1-Ecovative-epk-vn-112716-ph01",
              }}
            />
          </Div>

          <H5>Saving Money and Time &nbsp;|&nbsp; Reducing Error</H5>
          <P className="how-we-helped">
            What a company can do with organized data is truly amazing! With a
            uniform system in place to store all business processes and company
            data in one place. Ecovative was able to save a tremendous amount of
            time while reducing manual error greatly.
          </P>
          <P className="how-we-helped">
            Here are some of the many features we implemeted in Ecovative's ERP
            system:
          </P>
          <ul>
            <li>Integration</li>
            <li>Automation</li>
            <li>Data Analysis & Reporting</li>
            <li>Customer Relationship Management</li>
            <li>Financial management & Accounting</li>
            <li>Tracking and Visibility</li>
            <li>Human Resource Management</li>
            <li>Supply Chain Management</li>
            <li>Sales and Marketing</li>
          </ul>
          <H5>Using Simple Technologies | Less is More</H5>
          <P>
            We were able to gather all of this data with simple technologies. We
            created an API to communicate with the ERP system. Workstations in
            the warehouse had iPad tablets setup to communicate. Inside the
            office they could log directly into a CMS to input this data. For
            shipping and receiving we created QR codes to communicate directly
            to the API to keep track of inventory. Simple tools with a big
            outcome.
          </P>
        </article>
      ),
    },
  },
  {
    title: "DSCPA Accounting",
    description:
      "An HRM For pizza franchisees which allows for the easy managment of employees and company documents.",
    image: require("../../static/img/case-studies/dominos-pizzeria-case-study.png"),
    banner: require("../../static/img/case-studies/dominos-pizzeria-case-study.png"),
    tag: "Human Resource Management System",
    hashtags: ["Ruby", "Javascript", "UI / UX"],
    link: "/case_studies/dscpa_accounting",
    details: {
      quote: "Domino's Remote Office",
      title: "Ecovative Design",
      menu: {
        Company: "DSCPA",
        Location: "Cooper City, FL",
        Industry: "Accounting | Franchise",
        Size: "51-200 employees",
        "Project Type": "HR Platform",
        Website: {
          url: "https://www.dscpa.pizza/hr-portal",
          text: "www.dscpa.pizza",
        },
      },
      article: (
        <article className="article">
          <H2> For Domino's Owners, By Domino's Owners.</H2>
          <P>
            DSCPA is a full-service back-office accounting solutions provider for Pizza franchisees.  Working with many businesses in the Pizza Franchise such as Dominos Pizza, DSCPA has found many common familiarities in business operations amongst pizza franchisees.  This opened up a great opportunity for them to create an HR platform for Pizza Franches to help manage and operate their business.
          </P>
          <Div style={{backgroundColor: "black"}} className="dscpa-factory-image-container">
            <Image source={require("../../static/img/case-studies/dscpa-mockup.png")} />
          </Div>

          <H3 className="mt-4">All Employees, All The Time</H3>
          <P>
            There are three type of user's HR Admin, HR Reps, and DSCPA Accountants.  All three user's have access to the Employee's page.  Allowing them to quickly search, filter, and find any employee.  Also allowing them to add any newly hired employees.
          </P>
          <Div style={{backgroundColor: "black"}} className="dscpa-factory-image-container">
            <Image source={require("../../static/img/case-studies/all-employees.png")} />
          </Div>
          
          <H3 className="mt-4">HR Administration</H3>
          <P>
            A main feature of the HR platform is the ability for the HR Departments leader/administrator to manage their employees and their company's different store locations.
            Every HR representive's actions on the portable are recorded allowing transparency on which HR representive edited an employee's data and at which time.
          </P>
          <Div style={{backgroundColor: "black"}} className="dscpa-factory-image-container">
            <Image source={require("../../static/img/case-studies/hr-admin.png")} />
          </Div>

          <H3 className="mt-4">Digital File Cabinet</H3>
          <P>
            Keeping track of a company's paperwork is a task within itself.  The portal allows a franchise to upload important documents safe and securely.
          </P>
          <Div style={{backgroundColor: "black"}} className="dscpa-factory-image-container">
            <Image source={require("../../static/img/case-studies/digital-file-cabinet.png")} />
          </Div>
        </article>
      ),
    },
  },
  {
    title: "Vito's Pizzeria",
    description:
      "A pizza delivery application for delivery and order managment for over a dozen locations throughout Ohio.",
    image: require("../../static/img/logos/vitos.png"),
    banner: require("../../static/img/case-studies/vitos-pizza-case-study.png"),
    tag: "Food Delivery Application",
    hashtags: ["Ruby", "UI / UX", "Javascript"],
    link: "/case-studies/vitos-pizzeria",
    details: {
      quote: "LOCAL • FRESH • DIFFERENT",
      title: "Vito's Pizzeria",
      menu: {
        Company: "Vito's Pizzeria",
        Location: "Columbus, Ohio",
        Industry: "Pizza Delivery",
        Size: "51-200 employees",
        "Project Type": "Delivery Application",
        Website: {
          url: "https://www.vitos.com/",
          text: "www.vitos.com",
        },
      },
      article: (
        <article className="article">
          <H2>Serving Pizza for over 16+ years</H2>
          <P>
            Vito's Pizzeria is a local owned pizza shop, that has been in
            business since 1989. Vito's pizza has mastered a robust sauce and
            classic crust by making them homemade with quality ingredients. With
            over sixteen locations throughout Ohio, it was time to improve their
            loyal customer's experience digitally.
          </P>
          <Div
            style={{ backgroundColor: "red" }}
            className="vitos-factory-image-container"
          >
            <Image
              source={require("../../static/img/case-studies/vitos-pizzeria-mockup.png")}
            />
          </Div>
          <H2 className="mt-4">Improving the Customer Experience One Slice At A Time</H2>
          <P>
            Vito's already mastered customer satisfaction throughtout their
            years of being in the industry. Being an old school pizza shop the
            next move to keep their customer's even happier was by going
            digital. Vito's decided they wanted to make a pizza delivery
            application that allowed to have the best customer / user experience
            possible. That is when they decided making an delivery application
            that was both mobile and desktop friendly was their next best move
            in the business.
          </P>
          <P>
            Here is some of the key features that we built for their delivery
            application:
          </P>
          <ul>
            <li>Ingredient adjustment</li>
            <li>Online payment</li>
            <li>Order tracking</li>
            <li>Locating nearby restaurants via Geolocation</li>
            <li>Loyalty programs (Rewards) </li>
            <li>Order details &amp; history</li>
            <li>Account history</li>
            <li>Integrated content management system</li>
            <li>Order management</li>
            <li>Payments</li>
            <li>Delivery time estimation</li>
            <li>Reports &amp; analytics</li>
          </ul>
        </article>
      ),
    },
  },
  {
    title: "Coleman Real Estate",
    description:
      "Modern redesign of the digital presence for an upstate New York real estate broker.",
    image: require("../../static/img/logos/coleman518-logo.png"),
    banner: require("../../static/img/case-studies/coleman-real-estate-case-study.png"),
    tag: "Real Estate Application",
    hashtags: ["Ruby", "Javascript", "UI / UX"],
    link: "/case_studies/coleman_real_estate",
    details: {
      //"Website redesign and rebrand for a real estate broker located in Upstate New York.",
      quote: "Redesign and Rebrand of a New York Real Estate Website",
      title: "Coleman Real Estate",
      menu: {
        Company: "Coleman Real Estate",
        Location: "New York, New York",
        Industry: "Real Estate",
        Size: "51-200 employees",
        "Project Type": "Website Development | SEO",
        Website: {
          url: "https://www.coleman518.com/",
          text: "coleman518.com",
        },
      },
      article: (
        <article className="article">
          <H2>Dominating the upstate region one house at a time</H2>
          <P>
            Coleman Real Estate is a real estate brokerage located in New York's
            capital Albany. It was founded in 2017 by Crystal Coleman, a lady
            with many years of knowledge in the local real estate market. She
            and her team provide their services throughout Saratoga, Albany,
            Rensselaer, Schenectady, and Columbia counties.
          </P>
          <Div className="coleman-realestate-image-container">
            <Image
              style={{ width: "100%" }}
              source={require("../../static/img/case-studies/coleman_realestate_rewards.png")}
            />
          </Div>
          <H3 className="mt-4">
            With Business Booming it was time for a digital rebrand{" "}
          </H3>
          <P>
            While business increased and brand acknowledgment spread across the
            capital region. Their team decided it was time for a redesign of
            their website. So we decided to make their page a bit more clean and
            elegant while keeping the same color scheme, content, and Internet
            Data Exchange.
          </P>
          <Div className="coleman-realestate-image-container">
            <Image
              source={require("../../static/img/case-studies/coleman-before-after.gif")}
            />
          </Div>
        </article>
      ),
    },
  },
];
