import { ReactNode, useCallback, useEffect, useState } from "react";
import { Image, TouchableOpacity, useWindowDimensions } from "react-native";
import { HeaderProps, HeaderState, ScreenState } from ".";
import breakpointsConst from "./consts/breakpoints.const";
import { Pages } from "./pages";
import { Anchor, Div } from "./utils/tag.utils";

export const Header = (props: HeaderState) => {
  const [menuShown, setMenuShown] = useState(false);
  const toggleMenu = useCallback(
    (showMenu: boolean = !menuShown) => setMenuShown(showMenu),
    [menuShown]
  );

  const { width } = useWindowDimensions();

  const { breakpoints, pageRef, headerRef } = props;

  let small: boolean = width < breakpointsConst.xsmall;
  useEffect(() => {
    if (pageRef.current && headerRef.current) {
      (pageRef.current as any as HTMLElement).style.marginTop = (headerRef.current as any as HTMLElement).getBoundingClientRect().height + "px";
    }
    return () => {
      // cleanup
    }
  }, [pageRef.current, headerRef.current, menuShown, small]);
  


  const MenuItem = (_props) => (
    <li
      //   className="col-4 flex-col"
      style={{
        alignSelf: "flex-start",
        flexDirection: small ? "column" : "row",
        flex: 1,
        float: "left",
        textAlign: "center",
        width: small ? "100%" : "33%",
      }}
    >
      <Anchor
        onPress={() => {
          toggleMenu(false);
          props.pageRef.current?.scrollTo({ x: 0, y: 0, animated: true });
          _props.onPress();
        }}
        style={{
          display: "block",
          whiteSpace: "nowrap",
          color: "red",
          fontSize: 22,
        }}
        textStyle={{
          color: "red",
          fontSize: 22,
          fontWeight: "bold",
          width: "100%",
          whiteSpace: "nowrap",
        }}
      >
        {_props.children}
      </Anchor>
    </li>
  );
  const Menu = (_props) =>
    (small || menuShown) && (
      <ul
        className="flex-row col-12 display-block float-left"
        style={{
          listStyle: "none",
          justifyContent: "space-between",
          flex: 3,
          flexDirection: "column",
          marginBottom: 20,
        }}
      >
        <MenuItem onPress={() => props.navigation.navigate(Pages.Contact)}>
          Contact Us
        </MenuItem>
        <MenuItem onPress={() => props.navigation.navigate(Pages.AboutUs)}>
          Our Company
        </MenuItem>
        <MenuItem onPress={() => props.navigation.navigate(Pages.CaseStudies)}>
          Case Studies
        </MenuItem>
      </ul>
    );
  return (
    <Div
      ref={headerRef}
      style={{
        flex: 1,
        width: "100%",
        paddingVertical: 20,
        position: "fixed",
        zIndex: 9999999,
        height: small ? 240 : menuShown ? 170 : 120,
        backgroundColor: "white",
        boxShadow: "rgb(255 0 0) 0px 0px 0px 5px, black 0px 0px 10px 5px",
      }}
    >
      <TouchableOpacity
        // className="float-left"
        style={{ alignSelf: "flex-start", width: 400, display: "inline-block" } as any}
        onPress={() => props.navigation.navigate(Pages.HomePage)}
      >
        <Div
          className="col-10 float-left"
          style={{
            width: 400,
            maxHeight: "100%",
            height: 120,
            transform: "scale(0.75)",
            filter: "hue-rotate(10deg)",
          }}
        >
          <Image
            style={{ width: 400, height: 120 }}
            source={{ uri: require("../static/img/logos/enigma_logo.png") }}
          />
        </Div>
      </TouchableOpacity>
      {small || (
        <TouchableOpacity
          // className="float-right"
          style={{
            ...{float: "right"},
            width: 100,
            height: 100,
            position: "relative",
            alignSelf: "flex-end",
            display: "inline-block",
          } as any}
          onPress={() => toggleMenu(!menuShown)}
        >
          <Div
            className="col-1 float-right"
            style={{
              justifyContent: "space-between",
              left: 0,
              right: 0,
              top: "40%",
              bottom: "40%",
              position: "absolute",
            }}
          >
            <Div
              style={{
                height: 3,
                width: 40,
                backgroundColor: "black",
                marginBottom: 10,
              }}
            ></Div>
            <Div
              style={{
                height: 3,
                width: 40,
                backgroundColor: "black",
                marginBottom: 10,
              }}
            ></Div>
            <Div
              style={{
                height: 3,
                width: 40,
                backgroundColor: "black",
                marginBottom: 10,
              }}
            ></Div>
          </Div>
        </TouchableOpacity>
      )}
      <Menu></Menu>
    </Div>
  );
};
